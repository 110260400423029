import { useState } from "react";

const VynimkyTabulkaSiedma = ({
  tabulka,
  stlacene,
  viacMoznosti,
  updateStlacene,
  updateViacMoznosti,
}) => {
  const [pismena, setPismena] = useState([]);

  function insertXAfterSlash(inputArray) {
    // Find the index of "/"
    const index = inputArray.findIndex((elem) => elem === "/");

    // Check if "/" exists in the array
    if (index !== -1) {
      // Split the array into two parts: before and after "/"
      const beforeSlash = inputArray.slice(0, index + 1); // Include "/"
      const afterSlash = inputArray.slice(index + 1);

      // Create an array with three "x" elements
      const threeX = Array(1).fill(["x"]);

      // Concatenate the parts with three "x" elements in between
      return beforeSlash.concat(threeX, afterSlash);
    }

    return inputArray; // Return input array if "/" is not found
  }

  const handleClickSiedma = (elem, riadokCely) => {
    let aktualnePismena = "";
    // ked odtukavas
    if (pismena.includes(elem)) {
      aktualnePismena = pismena.filter((e) => e !== elem);
      setPismena(aktualnePismena);
      // update stlacene
    }
    // ked nic neni pridane tak sa moze hocico
    else if (pismena.length === 0) {
      aktualnePismena = [...pismena, elem];
      setPismena(aktualnePismena);
    }
    // ked sa nachadza v kombinacii
    else if (
      Object.hasOwn(
        tabulka.kombinacie,
        (pismena.join("") + elem).split("").sort().join("")
      )
    ) {
      aktualnePismena = [...pismena, elem];
      setPismena(aktualnePismena);
    }

    // ked jedno pridavas
    if (aktualnePismena.length === 1) {
      let kopiaStlacenych = [...insertXAfterSlash(stlacene)];

      kopiaStlacenych[kopiaStlacenych.length - 1] =
        riadokCely[riadokCely.length - 2] + riadokCely[riadokCely.length - 1];

      updateStlacene(kopiaStlacenych);
    }

    // ked viac
    else if (aktualnePismena.length > 1) {
      let kopiaStlacenych = [...insertXAfterSlash(stlacene)];

      //   MOZE BYT AJ VIAC CISEL TRI A VIAC

      let najdenaKombinacia =
        tabulka.kombinacie[aktualnePismena.sort().join("")];
      kopiaStlacenych[kopiaStlacenych.length - 1] = najdenaKombinacia;

      updateStlacene(kopiaStlacenych);
      //   pismena ZMAZAT PO UPRAVIT
    }
    // updateStlacene(nextCounters);
  };

  return (
    <div className="siedma_tabulka">
      {/* Nazvy */}
      {/* <div className="table-header" style={{ padding: 0 }}> */}
      <div className="cele_nazvy" style={{ width: "99.9%" }}>
        {tabulka.nazvy.map((nazov, i) => {
          if (Array.isArray(nazov)) {
            let pocet_stlpcov = nazov[nazov.length - 1].length;
            return (
              <div key={i} className={"group flex" + pocet_stlpcov}>
                {nazov.map((podnazov, indexPodnazov) => {
                  return (
                    <div key={indexPodnazov} className="row">
                      {podnazov.map((nazovInGroup, nazovInGroupIndex) => {
                        return (
                          <div
                            key={"6" + nazovInGroupIndex}
                            className="element_nazov"
                          >
                            {nazovInGroup}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          }
          return (
            <div className="row">
              <div key={"6" + i} className="element_nazov">
                {nazov}
              </div>
            </div>
          );
        })}
        <div className="row">
          <div className="element_nazov">↓</div>
        </div>
        <div className="row">
          <div className="element_nazov">↓</div>
        </div>
      </div>

      <div className="mobile_nazvy">
        {tabulka.nazvy.length > 1
          ? Array.isArray(tabulka.nazvy[1])
            ? tabulka.nazvy[1][0]
            : tabulka.nazvy[1]
          : "Názov nie je pridaný"}
      </div>

      {/* Hodnoty */}
      <div className="table-content">
        {" "}
        {tabulka.hodnoty.map((hodnota, index) => {
          let indexy = 8;
          let pismenko = hodnota[0];
          return (
            <div
              key={indexy.toString() + index}
              //   DOKONCIT
              onClick={() => {
                handleClickSiedma(pismenko, hodnota);
              }}
              className={
                pismena.includes(pismenko)
                  ? "stisknute table-row-siedma"
                  : "nestisknute table-row-siedma"
              }
            >
              {hodnota.map((hodnotaRiadok, i) => {
                return <div className="siedma_data">{hodnotaRiadok}</div>;
              })}
            </div>
          );
        })}
        {tabulka.kombinacie && (
          <div style={{ width: "99.9%" }}>
            <div className="dov_kombinacie">
              <div id="kombinacie">
                {" "}
                Dovolené kombinácie a kódy:{" "}
                {Object.keys(tabulka.kombinacie).map(
                  (elem, indexKombinacii) => {
                    if (
                      indexKombinacii ===
                      Object.keys(tabulka.kombinacie).length - 1
                    ) {
                      return (
                        elem.split("").join("+") +
                        "=" +
                        tabulka.kombinacie[elem]
                      );
                    }
                    return (
                      elem.split("").join("+") +
                      "=" +
                      tabulka.kombinacie[elem] +
                      " | "
                    );
                  }
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default VynimkyTabulkaSiedma;
