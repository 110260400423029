import { useState, useEffect } from "react";
import Nav from "../components/Nav";
import axios from "axios";
import Loading from "../components/LoadingScreen";
import TextField from "@mui/material/TextField";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const Schemy = () => {
  const serverAdresa = "https://vps.regada.sk/";
  // const serverAdresa = "http://localhost:8000/";

  const [error, setError] = useState(false);

  // rozbit kod

  const [celaSchema, setCelaSchema] = useState("");
  const [celaSchemaRozbita, setCelaSchemaRozbita] = useState([]);

  const [pdfUrlSchemy, setPdfUrlSchemy] = useState(null);
  const [nacitava, setNacitava] = useState(false);

  const fetchPDF = async (schemyVsetky) => {
    try {
      const response = await axios.get(`${serverAdresa}generateSchemy`, {
        responseType: "blob",
        params: {
          schemyVsetky,
        },
      });

      const pdfUrl = URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );

      setPdfUrlSchemy(pdfUrl);
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
  };

  const fetchImageNames = async () => {
    try {
      const response = await axios.get(`${serverAdresa}vsetkySchemyNazvy`);

      return response.data.imageNames.map((item) =>
        item.replace(".bmp", "").replace("z", "Z")
      );
    } catch (error) {
      console.error("Error fetching image names:", error);
    }
  };

  const handleDownloadPDF = async () => {
    try {
      setNacitava(true);
      let vygenerovatSchemu = true;

      const desifrovane = celaSchema
        .split(/[Zz]/)
        .filter((str) => str !== "")
        .map((str) => "Z" + str);

      setCelaSchemaRozbita(desifrovane);

      if (desifrovane.length > 4) {
        setError("Počet zadaných schém presiahol limit - max 4 schémy");
        vygenerovatSchemu = false;
      }
      if (vygenerovatSchemu) {
        const imageNames = await fetchImageNames(); // Await the result here

        for (let i = 0; i < desifrovane.length; i++) {
          const currentCode = desifrovane[i];

          if (!imageNames.includes(currentCode)) {
            vygenerovatSchemu = false;
            setError("Schéma " + currentCode + " nebola nájdená");
            break;
          }
        }
        if (vygenerovatSchemu) {
          await fetchPDF(desifrovane);
        }
      }
      setNacitava(false);
    } catch (error) {
      console.error("Error in handleDownloadPDF:", error);
      // Handle the error as needed
    }
  };

  useEffect(() => {
    if (pdfUrlSchemy) {
      const link = document.createElement("a");
      link.href = pdfUrlSchemy;
      // nastavit nazov schemy properly
      link.download = celaSchemaRozbita
        .filter((element) => element !== "")
        .join("_");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [pdfUrlSchemy]);

  return (
    <>
      {!nacitava && (
        <div>
          <Nav landingPage={true} />

          <div id="spajanieSchemContainer">
            <>
              <h2>Zadajte schémy zapojenia</h2>
              <div style={{ marginTop: "10px" }}>
                <TextField
                  variant="standard"
                  placeholder="napr. Z260aZ461a"
                  value={celaSchema}
                  onChange={(e) => {
                    setCelaSchema(e.target.value);
                    setError(false);
                  }}
                />
              </div>

              {error && (
                <Stack sx={{ width: "400px", marginTop: "10px" }} spacing={2}>
                  <Alert
                    severity="error"
                    onClose={() => {
                      setError(null);
                    }}
                  >
                    {error}
                  </Alert>
                </Stack>
              )}

              <div
                className="tlacitko"
                onClick={() => {
                  if (celaSchema.length > 0) {
                    handleDownloadPDF();
                  }
                }}
                style={{ margin: "20px 0" }}
              >
                Vytvoriť
              </div>
            </>
          </div>
        </div>
      )}
      {nacitava && <Loading />}
    </>
  );
};

export default Schemy;
