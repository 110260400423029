import Configurator from "./pages/Configurator";
import Desifrovac from "./pages/Desifrovac";
import Selectorrr from "./pages/selectorConfi";
import Nahlad from "./pages/Nahlad";
import Vynimky from "./pages/PridavanieVynimiek";
import Final from "./pages/FinalScreen";
import Schemy from "./pages/SpajanieSchem";
import Slovnik from "./pages/Slovnik";
import Editovanie from "./pages/Editovanie";
import NahratObrazky from "./pages/NahratObrazky";
import Poznamky from "./pages/PridavaniePoznamok";
import NoveServo from "./pages/NoveServo";
import Menu from "./pages/Menu";
import HistoriaDopytu from "./pages/HistoriaDopytu";
import PridatZakaznicke from "./pages/PridavanieZakaznickych";
import GlobalPoznamky from "./pages/globalPoznamky";

import { BrowserRouter, Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Selectorrr />} />
        <Route path="/serva/:typove" element={<Configurator />} />
        <Route path="/search" element={<Desifrovac />} />
        <Route path="/nahlad" element={<Nahlad />} />
        <Route path="/vynimky/:typove" element={<Vynimky />} />
        <Route path="/vynimky/:typove" element={<Vynimky />} />
        <Route path="/poznamky/:typove" element={<Poznamky />} />
        <Route path="/final" element={<Final />} />
        <Route path="/schemy" element={<Schemy />} />
        <Route path="/slovnik" element={<Slovnik />} />
        <Route path="/editovanie/:cisloTypove" element={<Editovanie />} />
        <Route path="/obrazky" element={<NahratObrazky />} />
        <Route path="/pridatnovy" element={<NoveServo />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/historia" element={<HistoriaDopytu />} />
        <Route path="/zakaznicke/:typove" element={<PridatZakaznicke />} />
        <Route path="/globalPoznamka" element={<GlobalPoznamky />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
