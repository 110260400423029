import { useState, useEffect } from "react";
import axios from "axios";
import Loading from "../components/LoadingScreen";
import { useParams } from "react-router-dom";
import regada_picture from "../images/logoTYPOGRAPHIAwhite.svg";
import sipka from "../images/icons8-arrow.svg";
import { useLocation, useNavigate } from "react-router-dom";

import TextField from "@mui/material/TextField";
import deleteIcon from "../images/icons8-delete.svg";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import AlertDialogSlide from "../components/AlertDialogSlide"; // Make sure to provide the correct path to the component

import MaxHeightTextarea from "../components/TextArea";

const PridatZakaznicke = () => {
  const serverAdresa = "https://vps.regada.sk/";
  // const serverAdresa = "http://localhost:8000/";

  const { typove } = useParams();
  const navigate = useNavigate();

  let zaheslovane = false;

  const [vsetkyUdaje, setVsetkyUdaje] = useState(null);
  const [vsetkyServopohony, setVsetkyServopohony] = useState(null);

  const [nacitava, setNacitava] = useState(false);

  const [koncovka, setKoncovka] = useState("");
  const [vyhotovenie, setVyhotovenie] = useState([""]);

  const [open, setOpen] = useState(false);

  const [error, setError] = useState(false);

  // const handleBeforeUnload = (event) => {
  //   event.preventDefault();

  //   const confirmationMessage = "Ste si istý že chcete opustiť stránku ?";

  //   event.returnValue = confirmationMessage;

  //   return confirmationMessage;
  // };

  async function getAllServa() {
    try {
      const response = await axios.get(`${serverAdresa}vsetko`);
      setVsetkyServopohony(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function upravitServoZakaznicke() {
    setNacitava(true);
    const obj = { ...vsetkyUdaje };

    const sortedVyhotovenia = Object.keys(obj.zakaznicke_vyhotovenia)
      .sort()
      .reduce((accumulator, key) => {
        accumulator[key] = obj.zakaznicke_vyhotovenia[key];

        return accumulator;
      }, {});

    setVsetkyUdaje({
      ...vsetkyUdaje,
      zakaznicke_vyhotovenia: sortedVyhotovenia,
    });

    try {
      await axios.put(`${serverAdresa}upravitZakaznicke`, {
        typove,
        sortedVyhotovenia,
      });
    } catch (err) {
      console.log(err);
    }
    setNacitava(false);

    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 1500);
  }

  async function getServo() {
    try {
      const response = await axios.get(`${serverAdresa}standard`, {
        params: { typove },
      });

      if (!response.data.zakaznicke_vyhotovenia) {
        setVsetkyUdaje({ ...response.data, zakaznicke_vyhotovenia: {} });
      } else {
        setVsetkyUdaje(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getServo();
  }, [typove]);

  useEffect(() => {
    getAllServa();
  }, []);

  // useEffect(() => {
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  return (
    <>
      {vsetkyUdaje && vsetkyServopohony && !nacitava && (
        <>
          <div id="headerup">
            {" "}
            <img
              id="back_header"
              src={sipka}
              alt="sipka"
              onClick={() =>
                navigate("/menu", {
                  state: {
                    zaheslovane,
                  },
                })
              }
              style={{ cursor: "pointer" }}
            ></img>
            <img
              src={regada_picture}
              id="typografia_header"
              alt="typography"
              onClick={() =>
                navigate("/menu", {
                  state: {
                    zaheslovane,
                  },
                })
              }
              style={{ cursor: "pointer" }}
            ></img>
            <div style={{ width: "60px" }}></div>
          </div>

          <AlertDialogSlide
            open={open}
            sprava="Zákaznické vyhotovenia boli úspešne upravené"
          />

          <div className="obalena_tab1">
            <div className="zakaznicke-margin">
              <div className="nazov_obrazok">
                <div>
                  <h1 id="nazov">{vsetkyUdaje.nazov}</h1>
                  <div id="vybavenia_obvidva">
                    <div>
                      <h2 className="vybavenia_nazvy">
                        Pridávanie zákazníckych vyhotovení
                      </h2>
                    </div>
                  </div>
                </div>
                <img
                  className="hlavny_obrazok"
                  style={{ marginRight: "60px", marginLeft: "40px" }}
                  src={`https://konfigurator.regada.sk/obrazkyVsetky/servopohony/${vsetkyUdaje.kod}.png`}
                  alt={vsetkyUdaje.nazov}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  marginTop: "20px",
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Koncovka"
                  variant="outlined"
                  type="text"
                  onChange={(e) => setKoncovka(e.target.value.toUpperCase())}
                  value={koncovka}
                  size="small"
                  style={{
                    width: "172px",
                    marginTop: "10px",
                  }}
                  inputProps={{ maxLength: 5 }}
                />

                <div>Popis ku zákaznickému vyhotoveniu:</div>
                {vyhotovenie.map((elem, indexVyhotovenia) => {
                  return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <MaxHeightTextarea
                        onChange={(elem) => {
                          const nextVyhotovenia = vyhotovenie.map((c, i) => {
                            if (i === indexVyhotovenia) {
                              // Increment the clicked counter
                              return elem.target.value;
                            } else {
                              // The rest haven't changed
                              return c;
                            }
                          });

                          setVyhotovenie(nextVyhotovenia);
                        }}
                        value={vyhotovenie[indexVyhotovenia]}
                        placeholder="..."
                      ></MaxHeightTextarea>
                      <img
                        src={deleteIcon}
                        alt="del"
                        className="trashcan"
                        onClick={() => {
                          setVyhotovenie(
                            vyhotovenie.filter((e, i) => i !== indexVyhotovenia)
                          );
                        }}
                      />
                    </div>
                  );
                })}

                <button
                  onClick={() => {
                    setVyhotovenie([...vyhotovenie, ""]);
                  }}
                  style={{
                    marginBottom: "20px",
                    width: "46px",
                  }}
                  className="tlacitko"
                >
                  +
                </button>
                {error && (
                  <Stack sx={{ width: "400px" }} spacing={2}>
                    <Alert
                      severity="error"
                      onClose={() => {
                        setError(null);
                      }}
                    >
                      {error}
                    </Alert>
                  </Stack>
                )}
                <button
                  onClick={() => {
                    if (
                      koncovka.length > 0 &&
                      !Object.keys(vsetkyUdaje.zakaznicke_vyhotovenia).includes(
                        koncovka
                      )
                    ) {
                      setError(false);
                      setVsetkyUdaje({
                        ...vsetkyUdaje,
                        zakaznicke_vyhotovenia: {
                          ...vsetkyUdaje.zakaznicke_vyhotovenia,
                          [koncovka]: vyhotovenie,
                        },
                      });
                      setKoncovka("");
                      setVyhotovenie([""]);
                    } else if (koncovka.length === 0) {
                      setError("Chýba koncovka");
                    } else {
                      setError("Vyhotovenia " + koncovka + " už je pridané.");
                    }
                  }}
                  style={{
                    marginBottom: "20px",
                    width: "90px",
                  }}
                  className="tlacitko"
                >
                  Pridať
                </button>

                <div
                  hidden={
                    Object.keys(vsetkyUdaje.zakaznicke_vyhotovenia).length > 0
                      ? false
                      : true
                  }
                >
                  <h2
                    // hidden={Object.keys(poznamka).length === 0 ? true : false}
                    className="vybavenia_nazvy"
                  >
                    Všetky vyhotovenia:
                  </h2>

                  <div className="oknodole_vynimky-poznamky">
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        // flexWrap: "wrap",
                        flexDirection: "column",
                        gap: "21px",
                      }}
                    >
                      {Object.keys(vsetkyUdaje.zakaznicke_vyhotovenia).map(
                        (koncovkaVyhotovenia) => {
                          return (
                            <div>
                              <div
                                style={{
                                  marginRight: "15px",
                                  marginBottom: "10px",
                                  marginTop: "6px",
                                }}
                              >
                                <div>
                                  <b>{koncovkaVyhotovenia}</b>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "start",
                                    marginTop: "4px",
                                  }}
                                >
                                  {vsetkyUdaje.zakaznicke_vyhotovenia[
                                    koncovkaVyhotovenia
                                  ].map((elem, indexVyhotovenia) => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "6px",
                                          width: "93%",
                                        }}
                                      >
                                        <div>
                                          <b>{"X "}</b>
                                        </div>
                                        <textarea
                                          value={elem}
                                          style={{
                                            all: "unset",
                                            width: "100%",
                                          }}
                                          onChange={(e) => {
                                            const nextVyhotovenia =
                                              vsetkyUdaje.zakaznicke_vyhotovenia[
                                                koncovkaVyhotovenia
                                              ].map((c, i) => {
                                                if (i === indexVyhotovenia) {
                                                  // Increment the clicked counter
                                                  return e.target.value;
                                                } else {
                                                  // The rest haven't changed
                                                  return c;
                                                }
                                              });

                                            setVsetkyUdaje({
                                              ...vsetkyUdaje,
                                              zakaznicke_vyhotovenia: {
                                                ...vsetkyUdaje.zakaznicke_vyhotovenia,
                                                [koncovkaVyhotovenia]:
                                                  nextVyhotovenia,
                                              },
                                            });
                                          }}
                                        ></textarea>
                                        <img
                                          src={deleteIcon}
                                          alt="del"
                                          className="trashcan"
                                          onClick={() => {
                                            setVsetkyUdaje({
                                              ...vsetkyUdaje,
                                              zakaznicke_vyhotovenia: {
                                                ...vsetkyUdaje.zakaznicke_vyhotovenia,
                                                [koncovkaVyhotovenia]:
                                                  vsetkyUdaje.zakaznicke_vyhotovenia[
                                                    koncovkaVyhotovenia
                                                  ].filter(
                                                    (e, i) =>
                                                      i !== indexVyhotovenia
                                                  ),
                                              },
                                            });
                                          }}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                <button
                                  onClick={() => {
                                    setVsetkyUdaje({
                                      ...vsetkyUdaje,
                                      zakaznicke_vyhotovenia: {
                                        ...vsetkyUdaje.zakaznicke_vyhotovenia,
                                        [koncovkaVyhotovenia]: [
                                          ...vsetkyUdaje.zakaznicke_vyhotovenia[
                                            koncovkaVyhotovenia
                                          ],
                                          "",
                                        ],
                                      },
                                    });
                                  }}
                                  style={{
                                    marginBottom: "10px",
                                    marginTop: "10px",
                                    padding: "5px 10px",
                                  }}
                                  className="tlacitko"
                                >
                                  +
                                </button>
                              </div>

                              <button
                                className="tlacitko-vymazat"
                                style={{ marginLeft: "0" }}
                                onClick={() => {
                                  setVsetkyUdaje((current) => {
                                    const copy = { ...current };

                                    delete copy["zakaznicke_vyhotovenia"][
                                      koncovkaVyhotovenia
                                    ];

                                    return copy;
                                  });
                                }}
                              >
                                Zmazať
                              </button>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
                <div
                  // hidden={Object.keys(poznamka).length === 0 ? true : false}
                  className="tlacitko"
                  style={{
                    width: "60px",
                    marginTop: "20px",
                    marginBottom: "40px",
                  }}
                  onClick={() => upravitServoZakaznicke()}
                >
                  Upraviť
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {!vsetkyUdaje && <Loading />}
      {nacitava && <Loading />}
    </>
  );
};

export default PridatZakaznicke;
