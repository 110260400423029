const Preklad = ({ hodnota, prekladac, jazyk }) => {
  return prekladac
    ? prekladac.hasOwnProperty(hodnota)
      ? jazyk === "EN"
        ? prekladac[hodnota][0]
        : jazyk === "RU"
        ? prekladac[hodnota][1]
        : hodnota
      : hodnota
    : hodnota;
};

export default Preklad;
