import Nazvy from "../Nazvy";
import Hodnota from "../Hodnota";

const NahladTabulky = ({
  stlacene,
  ktoryNazov,
  slovnik,
  selectedLanguage,
  celyRiadok,
  poznamka,
  vsetkyUdaje,
  zakaznicke,
  cisloHlavnejTab,
  classPreTabulka,
  idPreTabulku,
}) => {
  return (
    <div>
      {stlacene[cisloHlavnejTab].toLowerCase() !== "x" && (
        <div id={idPreTabulku + "0"} className={classPreTabulka}>
          {" "}
          <Nazvy
            tabulkaIteracia={{ nazvy: ktoryNazov[cisloHlavnejTab] }}
            prekladac={slovnik}
            jazyk={selectedLanguage}
            poznamka={poznamka}
          />
          <div className="table-content">
            <div className="stisknute table-row">
              {celyRiadok[cisloHlavnejTab].map((hodnotaRiadok, i) => {
                return (
                  <Hodnota
                    hodnotaRiadok={hodnotaRiadok}
                    slovnik={slovnik}
                    selectedLanguage={selectedLanguage}
                    poznamka={poznamka}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}

      {stlacene[cisloHlavnejTab] === "X" && (
        <div id={idPreTabulku + "0"} className={classPreTabulka}>
          <Nazvy
            tabulkaIteracia={
              vsetkyUdaje[
                "tabulka" +
                  (cisloHlavnejTab === 0 ? "1" : cisloHlavnejTab.toString())
              ][0]
            }
            prekladac={slovnik}
            jazyk={selectedLanguage}
            poznamka={poznamka}
          />
          <div className="table-content">
            <div className="poznamka-x">
              Doplňujúce poznámky ku konfigurácií, otázky:
            </div>
            <div className="stisknute table-row-x">
              <div className="table-data-zakaznicke">
                {zakaznicke[cisloHlavnejTab]}
              </div>
              <div className="table-data-zakaznicke">X</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NahladTabulky;
