import Tooltip from "@mui/material/Tooltip";
import Preklad from "./Preklad";

const Nazvy = ({
  tabulkaIteracia,
  prekladac,
  jazyk,
  farba,
  nevybrateMoznosti,
  poznamka,
}) => {
  function countConsecutiveKrat(arr) {
    let count = 0;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === "*") {
        count++;
      } else {
        break; // exit the loop when a different element is encountered
      }
    }

    return count + 1;
  }

  return (
    <>
      <div className="cele_nazvy">
        {tabulkaIteracia.nazvy.length > 0 && (
          <>
            {tabulkaIteracia.nazvy.map((group, i) => {
              // GROUP
              if (Array.isArray(group)) {
                let pocet_stlpcov = group[group.length - 1].length;
                return (
                  <div key={i} className={"group flex" + pocet_stlpcov}>
                    {group.map((row, rowIndex) => {
                      return (
                        <div
                          key={rowIndex}
                          className={
                            farba === "x" && nevybrateMoznosti
                              ? "row redd"
                              : "row"
                          }
                        >
                          {row.map((name, nameIndex) => {
                            if (name === "*") {
                              return null;
                            }

                            if (name.includes(")")) {
                              let rozdelene = name.split(" ");
                              let poznamky = rozdelene.filter(
                                (e) => e.includes(")") & !isNaN(e.slice(0, -1))
                              );
                              let text_hodnota = rozdelene
                                .filter((e) => !poznamky.includes(e))
                                .join(" ");

                              return (
                                <div className="element_nazov">
                                  <div>
                                    <Preklad
                                      hodnota={text_hodnota}
                                      jazyk={jazyk}
                                      prekladac={prekladac}
                                    />
                                  </div>

                                  {poznamky.map((e, i) => {
                                    let poznamkaUpravena = "";
                                    if (poznamka) {
                                      if (poznamka[e.slice(0, -1)]) {
                                        poznamkaUpravena =
                                          poznamka[e.slice(0, -1)];
                                      } else {
                                        poznamkaUpravena =
                                          "Poznámka nebola nájdena";
                                      }
                                    } else {
                                      poznamkaUpravena =
                                        "Poznámka nebola nájdena";
                                    }
                                    return (
                                      <Tooltip
                                        title={
                                          <Preklad
                                            hodnota={poznamkaUpravena}
                                            jazyk={jazyk}
                                            prekladac={prekladac}
                                          />
                                        }
                                      >
                                        <div className="tooltipExtraNazvy">
                                          {e}
                                        </div>
                                      </Tooltip>
                                    );
                                  })}
                                </div>
                              );
                            } else {
                              return (
                                <div className="element_nazov" key={nameIndex}>
                                  <Preklad
                                    hodnota={name}
                                    jazyk={jazyk}
                                    prekladac={prekladac}
                                  />
                                </div>
                              );
                            }
                          })}
                        </div>
                      );
                    })}
                  </div>
                );
              }
              // single hodntoa
              else {
                if (tabulkaIteracia.nazvy[i] === "*") {
                  return null;
                } else if (tabulkaIteracia.nazvy.length - 1 !== i) {
                  if (tabulkaIteracia.nazvy[i] === "*") {
                    return null;
                  } else if (tabulkaIteracia.nazvy[i + 1] === "*") {
                    // vypocitat velkost flexu
                    const velkostFlexu = countConsecutiveKrat(
                      tabulkaIteracia.nazvy.slice(i + 1)
                    ).toString();

                    if (group.includes(")")) {
                      let rozdelene = group.split(" ");
                      let poznamky = rozdelene.filter(
                        (e) => e.includes(")") & !isNaN(e.slice(0, -1))
                      );
                      let text_hodnota = rozdelene
                        .filter((e) => !poznamky.includes(e))
                        .join(" ");

                      return (
                        <div
                          className={
                            farba === "x" && nevybrateMoznosti
                              ? "row redd"
                              : "row"
                          }
                          style={{ flex: velkostFlexu }}
                        >
                          <div className="element_nazov">
                            <div style={{ paddingLeft: "4px" }}>
                              {prekladac
                                ? prekladac.hasOwnProperty(text_hodnota)
                                  ? jazyk === "EN"
                                    ? prekladac[text_hodnota][0]
                                    : jazyk === "RU"
                                    ? prekladac[text_hodnota][1]
                                    : text_hodnota
                                  : text_hodnota
                                : text_hodnota}
                            </div>

                            {poznamky.map((e, i) => {
                              let poznamkaUpravena = "";
                              if (poznamka) {
                                if (poznamka[e.slice(0, -1)]) {
                                  poznamkaUpravena = poznamka[e.slice(0, -1)];
                                } else {
                                  poznamkaUpravena = "Poznámka nebola nájdena";
                                }
                              } else {
                                poznamkaUpravena = "Poznámka nebola nájdena";
                              }
                              return (
                                <Tooltip
                                  title={
                                    prekladac
                                      ? prekladac.hasOwnProperty(
                                          poznamkaUpravena
                                        )
                                        ? jazyk === "EN"
                                          ? prekladac[poznamkaUpravena][0]
                                          : jazyk === "RU"
                                          ? prekladac[poznamkaUpravena][1]
                                          : poznamkaUpravena
                                        : poznamkaUpravena
                                      : poznamkaUpravena
                                  }
                                >
                                  <div className="tooltipExtraNazvy">{e}</div>
                                </Tooltip>
                              );
                            })}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className={
                            farba === "x" && nevybrateMoznosti
                              ? "row redd"
                              : "row"
                          }
                          style={{ flex: velkostFlexu }}
                        >
                          <div className="element_nazov">
                            {prekladac
                              ? prekladac.hasOwnProperty(group)
                                ? jazyk === "EN"
                                  ? prekladac[group][0]
                                  : jazyk === "RU"
                                  ? prekladac[group][1]
                                  : group
                                : group
                              : group}
                          </div>
                        </div>
                      );
                    }
                  }
                }
                if (group.includes(")")) {
                  let rozdelene = group.split(" ");
                  let poznamky = rozdelene.filter(
                    (e) => e.includes(")") & !isNaN(e.slice(0, -1))
                  );
                  let text_hodnota = rozdelene
                    .filter((e) => !poznamky.includes(e))
                    .join(" ");

                  return (
                    <div
                      className={
                        farba === "x" && nevybrateMoznosti ? "row redd" : "row"
                      }
                    >
                      <div className="element_nazov">
                        <div style={{ paddingLeft: "4px" }}>
                          <Preklad
                            hodnota={text_hodnota}
                            jazyk={jazyk}
                            prekladac={prekladac}
                          />
                        </div>

                        {poznamky.map((e, i) => {
                          let poznamkaUpravena = "";
                          if (poznamka) {
                            if (poznamka[e.slice(0, -1)]) {
                              poznamkaUpravena = poznamka[e.slice(0, -1)];
                            } else {
                              poznamkaUpravena = "Poznámka nebola nájdena";
                            }
                          } else {
                            poznamkaUpravena = "Poznámka nebola nájdena";
                          }
                          return (
                            <Tooltip
                              title={
                                <Preklad
                                  hodnota={poznamkaUpravena}
                                  jazyk={jazyk}
                                  prekladac={prekladac}
                                />
                              }
                            >
                              <div className="tooltipExtraNazvy">{e}</div>
                            </Tooltip>
                          );
                        })}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={
                        farba === "x" && nevybrateMoznosti ? "row redd" : "row"
                      }
                      style={{
                        visibility:
                          tabulkaIteracia.nazvy[i] === "#" ? "hidden" : "",
                      }}
                    >
                      <div className="element_nazov">
                        <Preklad
                          hodnota={group}
                          jazyk={jazyk}
                          prekladac={prekladac}
                        />
                      </div>
                    </div>
                  );
                }
              }
            })}

            <div
              className={
                farba === "x" && nevybrateMoznosti ? "row redd" : "row"
              }
              style={{
                visibility: tabulkaIteracia.nazvy.includes("#") ? "hidden" : "",
              }}
            >
              <div className="element_nazov">↓</div>
            </div>
          </>
        )}
      </div>

      <div className="mobile_nazvy">
        {tabulkaIteracia.nazvy.length > 0 ? (
          Array.isArray(tabulkaIteracia.nazvy[0]) ? (
            <Preklad
              hodnota={tabulkaIteracia.nazvy[0][0]}
              jazyk={jazyk}
              prekladac={prekladac}
            />
          ) : (
            <Preklad
              hodnota={tabulkaIteracia.nazvy[0]}
              jazyk={jazyk}
              prekladac={prekladac}
            />
          )
        ) : (
          "Názov nie je pridaný"
        )}
      </div>
    </>
  );
};

export default Nazvy;
