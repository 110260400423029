import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import regada_picture from "../images/logoTYPOGRAPHIAwhite.svg";
import sipka from "../images/icons8-arrow.svg";
import Loading from "../components/LoadingScreen";
import Formular from "../components/Formular";
import HornyIndex from "../components/HornyIndex";
import { Tooltip } from "@mui/material";
import Vybavenie from "../components/ConfiguratorComp/Vybavenie";

import NahladTabulky from "../components/NahladComp/NahladTabulky";

const Nahlad = () => {
  const serverAdresa = "https://vps.regada.sk/";
  // const serverAdresa = "http://localhost:8000/";

  const location = useLocation();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfUrlSchemy, setPdfUrlSchemy] = useState(null);
  const [pdfUrlNacrtky, setPdfUrlpdfUrlNacrtky] = useState(null);

  const [nacitava, setNacitava] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    location.state.selectedLanguage
  );
  window.scrollTo(0, 0);

  let stlacene = location.state.stlacene;
  let typove = location.state.typove;
  let vsetkyUdaje = location.state.vsetkyUdaje;
  let pismenka = location.state.pismenka;
  let kombinacieSpolu = location.state.kombinacieSpolu;
  let kombinaciePismena = location.state.kombinaciePismena;
  let slovnik = location.state.slovnik;
  let celyRiadok = location.state.celyRiadok;
  let ktoryNazov = location.state.ktoryNazov;
  let nacrtky = location.state.nacrtky;
  let schemy = location.state.schemy;
  let najdene = location.state.najdene;
  let najdenePopis = location.state.najdenePopis;
  let poznamka = location.state.poznamka;
  let nazov = vsetkyUdaje.nazov;
  let najdenePoznamky = location.state.najdenePoznamky;
  let dlhsieRozsireneCislo = location.state.dlhsieRozsireneCislo;

  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  const formattedDate = `${today.getDate()}.${
    today.getMonth() + 1
  }.${today.getFullYear()}`;

  let stlaceneCele = location.state.stlacene;

  let rozsireneVsetky = [];
  if (pismenka.length > 0) {
    vsetkyUdaje.tabulka7.hodnoty.forEach((e) => {
      if (pismenka.includes(e[0])) {
        rozsireneVsetky.push(e);
      }
    });
    stlaceneCele = [...stlacene, ...kombinacieSpolu[pismenka.join("")]];
  }

  let zakaznicke = ["", "-", "", "", "", "", ""];
  if (location.state.zakaznicke) {
    zakaznicke = location.state.zakaznicke;
  }

  // POZNAMKY NAJDENE V NAZVOCH

  if (location.state.najdenePoznamky) {
    let vsetkyNajdenePoznamky = [];

    ktoryNazov.forEach((e) => {
      if (Array.isArray(e)) {
        let jedenNazovNajdene = e
          .join(" ")
          .split(" ")
          .filter((e) => e.includes(")") & !isNaN(e.slice(0, -1)));
        if (jedenNazovNajdene.length > 0) {
          vsetkyNajdenePoznamky = [...vsetkyNajdenePoznamky, jedenNazovNajdene];
        }
      }
    });
    vsetkyNajdenePoznamky.forEach((e) => {
      e.forEach((j) => {
        if (!najdenePoznamky[8].includes(j))
          najdenePoznamky[8] = [...najdenePoznamky[8], j];
      });
    });
  }

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const fetchPDF = async () => {
    setNacitava(true);
    const vybavenniaVsetky = vsetkyUdaje.vybavenie;

    try {
      const response = await axios.post(
        `${serverAdresa}generatePDF`,
        {
          nazov,
          formattedDate,
          typove,
          ktoryNazov,
          celyRiadok,
          stlaceneCele,
          rozsireneVsetky,
          zakaznicke,
          slovnik,
          selectedLanguage,
          poznamka,
          najdenePoznamky,
          vybavenniaVsetky,
        },
        {
          responseType: "blob", // Specify the response type
        }
      );

      const pdfUrl = URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      setPdfUrl(pdfUrl);
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
    setNacitava(false);
  };

  const fetchPDFSpecial = async (typePdf) => {
    setNacitava(true);
    const prva = "Z1a";
    const druha = "Z11a";
    const tretia = "Z269r";
    try {
      const response = await axios.get(`${serverAdresa}${typePdf}`, {
        responseType: "blob",
        params: {
          typove,
          prva,
          druha,
          tretia,
        },
      });

      const pdfUrl = URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      if (typePdf === "generateSchemy") {
        setPdfUrlSchemy(pdfUrl);
      } else if (typePdf === "generateNacrtky") {
        setPdfUrlpdfUrlNacrtky(pdfUrl);
      }
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
    setNacitava(false);
  };

  const handleDownloadPDF = async () => {
    await fetchPDF(); // Fetch the PDF from the backend
  };

  const handleDownloadPDFSpecial = async (ktoryPdf) => {
    await fetchPDFSpecial(ktoryPdf); // Fetch the PDF from the backend
  };

  useEffect(() => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      // link.download = today.toDateString(); // Set the suggested filename
      link.download = "konfiguracia_regada";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [pdfUrl]);

  useEffect(() => {
    if (pdfUrlSchemy) {
      const link = document.createElement("a");
      link.href = pdfUrlSchemy;
      // link.download = today.toDateString(); // Set the suggested filename
      link.download = "schemy_regada_" + typove + "_" + vsetkyUdaje.nazov;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [pdfUrlSchemy]);

  useEffect(() => {
    if (pdfUrlNacrtky) {
      const link = document.createElement("a");
      link.href = pdfUrlNacrtky;
      // link.download = today.toDateString(); // Set the suggested filename
      link.download = "nacrty_regada_" + typove + "_" + vsetkyUdaje.nazov;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [pdfUrlNacrtky]);

  const classyTabulky = [
    "prva",
    "druha",
    "tretia",
    "stvrta",
    "piata",
    "siesta",
  ];

  return (
    <div>
      {nacitava && <Loading />}
      {!nacitava && (
        <>
          <div className={showModal ? "zoslabenie" : ""}>
            <div id="headerup">
              {" "}
              <img
                id="back_header"
                src={sipka}
                alt="sipka"
                onClick={() =>
                  navigate("/serva/" + typove, {
                    state: {
                      stlacene,
                      pismenka,
                      selectedLanguage,
                      zakaznicke,
                      celyRiadok,
                      ktoryNazov,
                      nacrtky,
                      schemy,
                      najdene,
                      najdenePopis,
                      najdenePoznamky,
                      poznamka,
                      dlhsieRozsireneCislo,
                    },
                  })
                }
              ></img>
              <Link id="typo_obal" to="/">
                <img
                  src={regada_picture}
                  id="typografia_header"
                  alt="typography"
                ></img>
              </Link>
              <div>
                <select
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  name="language"
                  id="language_header"
                >
                  <option value="SK">SK</option>
                  <option value="EN">EN</option>
                  <option value="RU">RU</option>
                </select>
              </div>
            </div>

            <div className="obalena_tab1">
              <div className="nahlad">
                <div className="nazov_obrazok">
                  <div>
                    <h1 id="nazov">
                      {" "}
                      {selectedLanguage === "EN"
                        ? "Configuration preview"
                        : selectedLanguage === "RU"
                        ? "Предпросмотр конфигурации"
                        : "Náhľad na konfiguráciu"}
                    </h1>
                    <h1 style={{ fontFamily: "CamptonMedium" }}>
                      {vsetkyUdaje.nazov}
                    </h1>
                  </div>
                  <img
                    className={
                      showModal
                        ? "zoslabenie-obrazok hlavny_obrazok"
                        : "hlavny_obrazok"
                    }
                    src={`https://konfigurator.regada.sk/obrazkyVsetky/servopohony/${vsetkyUdaje.kod}.png`}
                    alt={vsetkyUdaje.nazov}
                  />
                </div>

                <div id="vybavenia_obvidva" style={{ marginTop: "-6px" }}>
                  <div className="vybavenia_jedno">
                    <h2 className="vybavenia_nazvy">
                      {" "}
                      {selectedLanguage === "EN"
                        ? "Standard equipment:"
                        : selectedLanguage === "RU"
                        ? "Стандартное оснащение:"
                        : "Štandardné vybavenie:"}
                    </h2>
                    <ul>
                      {" "}
                      <Vybavenie
                        vybaveniaData={vsetkyUdaje.vybavenie.standardne}
                        slovnik={slovnik}
                        selectedLanguage={selectedLanguage}
                        poznamka={poznamka}
                      />
                    </ul>
                  </div>

                  <div>
                    <h2 className="vybavenia_nazvy">
                      {selectedLanguage === "EN"
                        ? "Additional equipment"
                        : selectedLanguage === "RU"
                        ? "Добавочное оснащение"
                        : "Rozšírené vybavenie:"}
                    </h2>

                    <ul>
                      <Vybavenie
                        vybaveniaData={vsetkyUdaje.vybavenie.rozsirene}
                        slovnik={slovnik}
                        selectedLanguage={selectedLanguage}
                        poznamka={poznamka}
                      />
                    </ul>
                  </div>
                </div>
                <h2
                  className="vybavenia_nazvy"
                  style={{
                    marginBottom: "0px",
                    fontStyle: "italic",
                    marginTop: "13px",
                  }}
                >
                  {selectedLanguage === "EN"
                    ? "Selected equipment and parameters"
                    : selectedLanguage === "RU"
                    ? "Выбранное оборудование и параметры"
                    : "Zvolená výbava a parametre"}
                </h2>

                <div className="board">
                  <div>
                    <div id="objednavaci_kod">
                      <div className="objednavaci_riadok" id="order_code_label">
                        {selectedLanguage === "EN"
                          ? "Order code"
                          : selectedLanguage === "RU"
                          ? "Марка исполнения"
                          : "Objednávací kód"}
                      </div>
                      <div className="prava_strana">
                        <div id="kodmaly" className="objednavaci_riadok">
                          {typove.length > 3
                            ? typove.slice(0, 2) + " " + typove.slice(2)
                            : typove}
                          .
                        </div>
                        <div className="kod_cisla">
                          {" "}
                          {stlacene.map((ciselo) => {
                            return (
                              <div className="objednavaci_riadok cislo">
                                {ciselo}
                              </div>
                            );
                          })}
                        </div>
                        {!(pismenka.length === 0) ? (
                          kombinacieSpolu[pismenka.join("")]
                            .split("")
                            .map((jednoRozsirene, jednoRozsireneIndex) => {
                              return (
                                <div className="objednavaci_riadok cislo">
                                  {jednoRozsirene}
                                </div>
                              );
                            })
                        ) : (
                          <>
                            <div className="objednavaci_riadok cislo">x</div>
                            <div className="objednavaci_riadok cislo">x</div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="container">
                      {classyTabulky.map((e, i) => {
                        return (
                          <NahladTabulky
                            stlacene={stlacene}
                            ktoryNazov={ktoryNazov}
                            slovnik={slovnik}
                            selectedLanguage={selectedLanguage}
                            celyRiadok={celyRiadok}
                            poznamka={poznamka}
                            vsetkyUdaje={vsetkyUdaje}
                            zakaznicke={zakaznicke}
                            cisloHlavnejTab={i === 0 ? 0 : i + 1}
                            classPreTabulka={
                              dlhsieRozsireneCislo === 3
                                ? e + "_tabulka-3"
                                : e + "_tabulka"
                            }
                            idPreTabulku={e}
                          />
                        );
                      })}

                      <div className="siedma_tabulka">
                        {kombinaciePismena.includes(pismenka.join("")) ? (
                          <div className="table-header">
                            {vsetkyUdaje.tabulka7.nazvy.map((nazov, i) => {
                              return (
                                <div key={"6" + i} className="siedma_item">
                                  {slovnik.hasOwnProperty(nazov)
                                    ? selectedLanguage === "EN"
                                      ? slovnik[nazov][0]
                                      : selectedLanguage === "RU"
                                      ? slovnik[nazov][1]
                                      : nazov
                                    : nazov}
                                </div>
                              );
                            })}
                            <div className="siedma_item">↓</div>
                            <div className="siedma_item">↓</div>
                          </div>
                        ) : null}

                        <div className="table-content">
                          {pismenka.map((letter) => {
                            return vsetkyUdaje.tabulka7.hodnoty.map(
                              (cur_hodnota) => {
                                if (cur_hodnota[0] === letter) {
                                  return (
                                    <div
                                      // key={indexy.toString() + index}
                                      className="table-row-siedma stisknute"
                                    >
                                      {cur_hodnota.map((hodnotaRiadok, i) => {
                                        if (hodnotaRiadok.includes(")")) {
                                          let rozdelene =
                                            hodnotaRiadok.split(" ");
                                          let poznamky = rozdelene.filter(
                                            (e) =>
                                              e.includes(")") &
                                              !isNaN(e.slice(0, -1))
                                          );
                                          let text_hodnota = rozdelene
                                            .filter(
                                              (e) => !poznamky.includes(e)
                                            )
                                            .join(" ");

                                          return (
                                            <div
                                              className="siedma_data"
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                gap: "5px",
                                              }}
                                            >
                                              <div>
                                                {slovnik ? (
                                                  slovnik.hasOwnProperty(
                                                    text_hodnota
                                                  ) ? (
                                                    selectedLanguage ===
                                                    "EN" ? (
                                                      <HornyIndex
                                                        hodnotaRiadok={
                                                          slovnik[
                                                            text_hodnota
                                                          ][0]
                                                        }
                                                      />
                                                    ) : selectedLanguage ===
                                                      "RU" ? (
                                                      <HornyIndex
                                                        hodnotaRiadok={
                                                          slovnik[
                                                            text_hodnota
                                                          ][1]
                                                        }
                                                      />
                                                    ) : (
                                                      <HornyIndex
                                                        hodnotaRiadok={
                                                          text_hodnota
                                                        }
                                                      />
                                                    )
                                                  ) : (
                                                    <HornyIndex
                                                      hodnotaRiadok={
                                                        text_hodnota
                                                      }
                                                    />
                                                  )
                                                ) : (
                                                  <HornyIndex
                                                    hodnotaRiadok={text_hodnota}
                                                  />
                                                )}
                                              </div>
                                              {poznamky.map((e, i) => {
                                                let poznamkaUpravena = "";
                                                if (poznamka) {
                                                  if (
                                                    poznamka[e.slice(0, -1)]
                                                  ) {
                                                    poznamkaUpravena =
                                                      poznamka[e.slice(0, -1)];
                                                  } else {
                                                    poznamkaUpravena =
                                                      "Poznámka nebola nájdena";
                                                  }
                                                } else {
                                                  poznamkaUpravena =
                                                    "Poznámka nebola nájdena";
                                                }
                                                return (
                                                  <Tooltip
                                                    title={
                                                      slovnik
                                                        ? slovnik.hasOwnProperty(
                                                            poznamkaUpravena
                                                          )
                                                          ? selectedLanguage ===
                                                            "EN"
                                                            ? slovnik[
                                                                poznamkaUpravena
                                                              ][0]
                                                            : selectedLanguage ===
                                                              "RU"
                                                            ? slovnik[
                                                                poznamkaUpravena
                                                              ][1]
                                                            : poznamkaUpravena
                                                          : poznamkaUpravena
                                                        : poznamkaUpravena
                                                    }
                                                  >
                                                    <div className="tooltipExtra">
                                                      {e}
                                                    </div>
                                                  </Tooltip>
                                                );
                                              })}
                                            </div>
                                          );
                                        }
                                        return (
                                          <div className="siedma_data">
                                            {/* pre -1 */}
                                            {slovnik ? (
                                              slovnik.hasOwnProperty(
                                                hodnotaRiadok
                                              ) ? (
                                                selectedLanguage === "EN" ? (
                                                  <HornyIndex
                                                    hodnotaRiadok={
                                                      slovnik[hodnotaRiadok][0]
                                                    }
                                                  />
                                                ) : selectedLanguage ===
                                                  "RU" ? (
                                                  <HornyIndex
                                                    hodnotaRiadok={
                                                      slovnik[hodnotaRiadok][1]
                                                    }
                                                  />
                                                ) : (
                                                  <HornyIndex
                                                    hodnotaRiadok={
                                                      hodnotaRiadok
                                                    }
                                                  />
                                                )
                                              ) : (
                                                <HornyIndex
                                                  hodnotaRiadok={hodnotaRiadok}
                                                />
                                              )
                                            ) : (
                                              <HornyIndex
                                                hodnotaRiadok={hodnotaRiadok}
                                              />
                                            )}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  );
                                } else {
                                  return null;
                                }
                              }
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tlacidla_riadok">
                  <div
                    className="tlacitko"
                    onClick={() =>
                      navigate("/serva/" + typove, {
                        state: {
                          stlacene,
                          pismenka,
                          selectedLanguage,
                          zakaznicke,
                          celyRiadok,
                          ktoryNazov,
                          nacrtky,
                          schemy,
                          najdene,
                          najdenePopis,
                          najdenePoznamky,
                          poznamka,
                          dlhsieRozsireneCislo,
                        },
                      })
                    }
                  >
                    {" "}
                    {selectedLanguage === "EN"
                      ? "← Back"
                      : selectedLanguage === "RU"
                      ? "← Назад"
                      : "← Naspäť"}
                  </div>
                  {/* <div
                    className="tlacitko"
                    onClick={() => handleDownloadPDFSpecial("generateSchemy")}
                  >
                    {selectedLanguage === "EN"
                      ? "Diagrams"
                      : selectedLanguage === "RU"
                      ? "Схемы"
                      : "Schémy"}
                  </div> */}
                  {/* <div
                    className="tlacitko"
                    onClick={() => handleDownloadPDFSpecial("generateNacrtky")}
                  >
                    {selectedLanguage === "EN"
                      ? "Drawings"
                      : selectedLanguage === "RU"
                      ? "Эскиз"
                      : "Rozm. Náčrt"}
                  </div> */}

                  <div className="tlacitko" onClick={handleDownloadPDF}>
                    {selectedLanguage === "EN"
                      ? "Save PDF"
                      : selectedLanguage === "RU"
                      ? "Сохранить PDF"
                      : "Uložiť PDF"}
                  </div>

                  <div className="tlacitko" onClick={() => setShowModal(true)}>
                    {selectedLanguage === "EN"
                      ? "Send a REQUEST"
                      : selectedLanguage === "RU"
                      ? "Отправить ЗАПРОС"
                      : "Poslať DOPYT"}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showModal && (
            <Formular
              setShowModal={setShowModal}
              vsetkyUdaje={vsetkyUdaje}
              stlaceneCele={stlaceneCele}
              typove={typove}
              ktoryNazov={ktoryNazov}
              celyRiadok={celyRiadok}
              rozsireneVsetky={rozsireneVsetky}
              zakaznicke={zakaznicke}
              slovnik={slovnik}
              selectedLanguage={selectedLanguage}
              poznamka={poznamka}
              najdenePoznamky={najdenePoznamky}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Nahlad;
