import { useState, useEffect } from "react";
import axios from "axios";

import regada_picture from "../images/logoTYPOGRAPHIAwhite.svg";
import sipka from "../images/icons8-arrow.svg";
import { useLocation, useNavigate } from "react-router-dom";

import Loading from "../components/LoadingScreen";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import TextField from "@mui/material/TextField";

import InfoIcon from "@mui/icons-material/Info";
import DocumentaciaDialog from "../components/DocumentaciaDialog";
import Button from "@mui/material/Button";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import AlertDialogSlide from "../components/AlertDialogSlide";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import EditTabulka from "../components/EditovanieComp/Edit_Tabulka";

const NoveServo = () => {
  const serverAdresa = "https://vps.regada.sk/";
  // const serverAdresa = "http://localhost:8000/";

  const [typove, setTypove] = useState("260");

  // bez pomlcky

  const [selectedFile, setSelectedFile] = useState(null);
  const [vsetkyServopohony, setVsetkyServopohony] = useState(null);
  const [vsetkyUdaje, setVsetkyUdaje] = useState(null);
  const [nevybrateMoznosti, setNevybrateMoznosti] = useState(false);
  const [mode, setMode] = useState("");

  const [presuvanie, setPresuvanie] = useState(false);

  const [kombinaciaKey, setKombinaciaKey] = useState("");
  const [kombinaciaValue, setKombinaciaValue] = useState("");

  const [mazanie, setMazanie] = useState(false);
  const [stlpce, setStlpce] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  let zaheslovane = location.state.zaheslovane;

  const [upload, setUpload] = useState(false);

  const [nacitava, setNacitava] = useState(false);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const updateVsetkyUdaje = (newVsetkyUdaje) => {
    setVsetkyUdaje(newVsetkyUdaje);
  };
  const classyTabulky = [
    "prva",
    "druha",
    "tretia",
    "stvrta",
    "piata",
    "siesta",
  ];

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("priecinok", "public/obrazkyVsetky/servopohony");
    formData.append("useOriginalFileName", vsetkyUdaje.kod);

    try {
      const response = await axios.post(`${serverAdresa}uploadFTP`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("File uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading file:", error);
    }

    setUpload(true);
  };

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    const confirmationMessage = "Ste si istý že chcete opustiť stránku ?";
    event.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  async function pridatNoveServo() {
    setNacitava(true);
    try {
      await axios.put(`${serverAdresa}pridatNoveServo`, {
        vsetkyUdaje,
      });
      getAllServa();
    } catch (err) {
      console.log(err);
    }
    setNacitava(false);
  }

  const deleteKombinacia = (keyToDelete) => {
    const newObj = { ...vsetkyUdaje }; // Create a shallow copy of the object
    delete newObj.tabulka7.kombinacie[keyToDelete]; // Delete the specified key
    setVsetkyUdaje(newObj); // Update the state with the new object
  };

  const handlehodnotaChange = (
    ktoraTabulka,
    novaHodnota,
    indexTabulky,
    indexRiadka,
    indexHodnoty
  ) => {
    const update_hodnoty = vsetkyUdaje["tabulka" + ktoraTabulka];

    update_hodnoty[indexTabulky].hodnoty[indexRiadka][indexHodnoty] =
      novaHodnota;

    setVsetkyUdaje({ ...vsetkyUdaje });
  };

  const handleNazovChange = (
    ktoraTabulka,
    novaHodnota,
    indexTabulky,
    indexNazov
  ) => {
    const update_hodnoty = vsetkyUdaje["tabulka" + ktoraTabulka];

    update_hodnoty[indexTabulky].nazvy[indexNazov] = novaHodnota;
    setVsetkyUdaje({ ...vsetkyUdaje });
  };

  const pridatRiadok = (cisloTabulky) => {
    const update_hodnoty = vsetkyUdaje["tabulka" + cisloTabulky][0];
    let novyRiadok = new Array(update_hodnoty.hodnoty[0].length).fill("");
    update_hodnoty.hodnoty.push(novyRiadok);
    setVsetkyUdaje({ ...vsetkyUdaje });
  };

  const odstranitRiadok = (riadok, cisloTabulky) => {
    let update_hodnoty = vsetkyUdaje["tabulka" + cisloTabulky][0];
    update_hodnoty.hodnoty = update_hodnoty.hodnoty.filter((e) => e !== riadok);
    setVsetkyUdaje({ ...vsetkyUdaje });
  };

  async function getServo() {
    setNacitava(true);
    try {
      const response = await axios.get(`${serverAdresa}standard`, {
        params: { typove },
      });
      response.data.kod = "";
      setVsetkyUdaje(response.data);
    } catch (e) {
      console.log(e);
    }
    setNacitava(false);
  }

  async function getAllServa() {
    try {
      const response = await axios.get(`${serverAdresa}vsetko`);
      setVsetkyServopohony(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getAllServa();
  }, []);

  useEffect(() => {
    getServo();
  }, [typove]);

  const handleServoChange = (e) => {
    setTypove(e.target.value);
  };

  const handleNewServo = () => {
    setVsetkyUdaje({
      kod: "",
      nazov: "",
      obrazok: "",
      kategoria: "standard",
      otacky: "jedno",
      tabulka1: [
        {
          nazvy: ["", ""],
          hodnoty: [["", "", ""]],
        },
      ],
      tabulka2: [
        {
          nazvy: ["", ""],
          hodnoty: [["", "", ""]],
        },
      ],
      tabulka3: [
        {
          nazvy: ["", ""],
          hodnoty: [["", "", ""]],
        },
      ],
      tabulka4: [
        {
          nazvy: ["", ""],
          hodnoty: [["", "", ""]],
        },
      ],
      tabulka5: [
        {
          nazvy: ["", ""],
          hodnoty: [["", "", ""]],
        },
      ],
      tabulka6: [
        {
          nazvy: ["", ""],
          hodnoty: [["", "", ""]],
        },
      ],
      tabulka7: {
        nazvy: ["Kód", "Rozšírené vybavenie", "Schéma zapojenia"],
        hodnoty: [["...", "...", "...", "...", "..."]],
        kombinacie: {},
      },
      vybavenie: {
        standardne: [""],
        rozsirene: [""],
      },
      kombinacie: {},
    });
    setMode("nove");
  };

  // useEffect(() => {
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  return (
    <>
      {vsetkyUdaje && !nacitava && vsetkyServopohony && (
        <>
          <div id="headerup">
            {" "}
            <img
              id="back_header"
              src={sipka}
              alt="sipka"
              onClick={() =>
                navigate("/menu", {
                  state: {
                    zaheslovane,
                  },
                })
              }
              style={{ cursor: "pointer" }}
            ></img>
            <img
              src={regada_picture}
              id="typografia_header"
              alt="typography"
              onClick={() =>
                navigate("/menu", {
                  state: {
                    zaheslovane,
                  },
                })
              }
              style={{ cursor: "pointer" }}
            ></img>
            <div style={{ width: "60px" }}></div>
          </div>

          <div className="obalena_tab1">
            <div
              style={{
                margin: "auto",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  className="nazov"
                  style={{
                    textAlign: "center",
                    fontFamily: "CamptonBold",
                    fontSize: "20px",
                  }}
                >
                  Pridať nové servo:{" "}
                  {mode === "nove"
                    ? "Čisté"
                    : mode === "edit"
                    ? "Šablona"
                    : mode}
                </div>

                <Box
                  sx={{ maxWidth: 120 }}
                  hidden={mode === "edit" ? false : true}
                  style={{ margin: "10px 0 " }}
                >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Typ.</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={typove}
                      label="Typove"
                      onChange={handleServoChange}
                    >
                      {vsetkyServopohony.map((e) => {
                        return <MenuItem value={e.kod}>{e.kod}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Box>

                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <div
                    className={
                      mode === "nove" ? "tlacitko_stlacene" : "tlacitko"
                    }
                    onClick={handleNewServo}
                  >
                    Čisté
                  </div>

                  <div
                    className={
                      mode === "edit" ? "tlacitko_stlacene" : "tlacitko"
                    }
                    onClick={() => {
                      setMode("edit");
                      getServo();
                    }}
                  >
                    Šablona
                  </div>
                </div>
              </div>
            </div>
            {mode.length !== 0 ? (
              <>
                {/* <div className="nazov_obrazok">
                  <div className="detail">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <input
                        value={vsetkyUdaje.kod}
                        placeholder="typové"
                        style={{ width: "60px", marginBottom: "10px" }}
                        onChange={(e) =>
                          setVsetkyUdaje({
                            ...vsetkyUdaje,
                            kod: e.target.value,
                          })
                        }
                      />
                      <input
                        id="nazov"
                        value={vsetkyUdaje.nazov}
                        placeholder="názov"
                        onChange={(e) =>
                          setVsetkyUdaje({
                            ...vsetkyUdaje,
                            nazov: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div id="vybavenia_obvidva">
                      <div hidden={nevybrateMoznosti}>
                        <h2 className="vybavenia_nazvy">
                          Štandardné vybavenie:
                        </h2>
                        <ul>
                          {" "}
                          {vsetkyUdaje.vybavenie.standardne.map((elem, i) => {
                            return <li key={i + "s"}>{elem}</li>;
                          })}
                        </ul>
                      </div>

                      <div hidden={nevybrateMoznosti}>
                        <h2 className="vybavenia_nazvy">
                          Rozšírené vybavenie:
                        </h2>
                        <ul>
                          {vsetkyUdaje.vybavenie.rozsirene.map((elem, i) => {
                            if (elem[0] === elem[0].toUpperCase()) {
                              return <li key={i + "r"}>{elem}</li>;
                            } else {
                              return (
                                <ul>
                                  <li key={i + "r"}>{elem}</li>
                                </ul>
                              );
                            }
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                    <button onClick={handleUpload}>Nahrať</button>
                  </div>
                  {upload && (
                    <img
                      className="hlavny_obrazok pravy_margin-konfi"
                      src={`https://konfigurator.regada.sk/obrazkyVsetky/servopohony/${vsetkyUdaje.kod}.png`}
                      alt="Nie je nahratý"
                    />
                  )}

                  {!upload && (
                    <img
                      className="hlavny_obrazok pravy_margin-konfi"
                      src={`https://konfigurator.regada.sk/obrazkyVsetky/servopohony/${vsetkyUdaje.kod}.png`}
                      alt="Nie je nahratý"
                    />
                  )}
                </div>
                <div className="board lavy_margin-konfi">
                  <div className="container">
                    {vsetkyUdaje.tabulka1.map((hodnotaArray, indexTabulky) => {
                      return (
                        <div
                          id={"prva" + indexTabulky}
                          className="prva_tabulka"
                        >
                          <div className="cele_nazvy">
                            {hodnotaArray.nazvy.map((group, indexNazov) => {
                              if (Array.isArray(group)) {
                                let pocet_stlpcov =
                                  group[group.length - 1].length;
                                return (
                                  <div
                                    key={indexNazov}
                                    className={"group flex" + pocet_stlpcov}
                                  >
                                    {group.map((row, rowIndex) => (
                                      <div key={rowIndex} className="row">
                                        {row.map((name, nameIndex) => (
                                          <div
                                            className="element_nazov"
                                            key={nameIndex}
                                          >
                                            {name}
                                          </div>
                                        ))}
                                      </div>
                                    ))}
                                  </div>
                                );
                              } else {
                                return (
                                  <div className="group" key={indexNazov}>
                                    <div className="row">
                                      <input
                                        className="element_nazov_edit"
                                        placeholder="..."
                                        value={group}
                                        onChange={(e) =>
                                          handleNazovChange(
                                            1,
                                            e.target.value,
                                            indexTabulky,
                                            indexNazov
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            })}

                            <div className="group">
                              <div className="row">
                                <div className="element_nazov">↓</div>
                              </div>
                            </div>
                          </div>

                          <div className="table-content">
                            {" "}
                            {hodnotaArray.hodnoty.map(
                              (hodnota, indexRiadka) => {
                                let indexy = 0;

                                return (
                                  <div className="edit-row-cely">
                                    <div
                                      key={indexy.toString() + indexRiadka}
                                      className="table-row-edit"
                                    >
                                      {hodnota.map(
                                        (hodnotaRiadok, indexHodnoty) => {
                                          return (
                                            <input
                                              className="table-data-edit"
                                              value={hodnotaRiadok}
                                              placeholder="..."
                                              onChange={(e) =>
                                                handlehodnotaChange(
                                                  1,
                                                  e.target.value,
                                                  indexTabulky,
                                                  indexRiadka,
                                                  indexHodnoty
                                                )
                                              }
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                    <div
                                      className="tlacitko-vymazat"
                                      onClick={() => {
                                        odstranitRiadok(hodnota, 1);
                                      }}
                                    >
                                      x
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div
                            class="tlacitko"
                            style={{ width: "120px" }}
                            onClick={() => pridatRiadok(1)}
                          >
                            Pridať riadok +
                          </div>
                        </div>
                      );
                    })}
                    {vsetkyUdaje.tabulka2.map((hodnotaArray, indexTabulky) => {
                      return (
                        <div
                          id={"druha" + indexTabulky}
                          className="druha_tabulka"
                        >
                          <div className="cele_nazvy">
                            {hodnotaArray.nazvy.map((group, indexNazov) => {
                              if (Array.isArray(group)) {
                                let pocet_stlpcov =
                                  group[group.length - 1].length;
                                return (
                                  <div
                                    key={indexNazov}
                                    className={"group flex" + pocet_stlpcov}
                                  >
                                    {group.map((row, rowIndex) => (
                                      <div key={rowIndex} className="row">
                                        {row.map((name, nameIndex) => (
                                          <div
                                            className="element_nazov"
                                            key={nameIndex}
                                          >
                                            {name}
                                          </div>
                                        ))}
                                      </div>
                                    ))}
                                  </div>
                                );
                              } else {
                                return (
                                  <div className="group" key={indexNazov}>
                                    <div className="row">
                                      <input
                                        className="element_nazov_edit"
                                        placeholder="..."
                                        value={group}
                                        onChange={(e) =>
                                          handleNazovChange(
                                            2,
                                            e.target.value,
                                            indexTabulky,
                                            indexNazov
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            })}

                            <div className="group">
                              <div className="row">
                                <div className="element_nazov">↓</div>
                              </div>
                            </div>
                          </div>

                          <div className="table-content">
                            {" "}
                            {hodnotaArray.hodnoty.map(
                              (hodnota, indexRiadka) => {
                                let indexy = 2;

                                return (
                                  <div className="edit-row-cely">
                                    <div
                                      key={indexy.toString() + indexRiadka}
                                      className="table-row-edit"
                                    >
                                      {hodnota.map(
                                        (hodnotaRiadok, indexHodnoty) => {
                                          return (
                                            <input
                                              className="table-data-edit"
                                              value={hodnotaRiadok}
                                              placeholder="..."
                                              onChange={(e) =>
                                                handlehodnotaChange(
                                                  2,
                                                  e.target.value,
                                                  indexTabulky,
                                                  indexRiadka,
                                                  indexHodnoty
                                                )
                                              }
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                    <div
                                      className="tlacitko-vymazat"
                                      onClick={() => {
                                        odstranitRiadok(hodnota, 2);
                                      }}
                                    >
                                      x
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div
                            class="tlacitko"
                            style={{ width: "120px" }}
                            onClick={() => pridatRiadok(2)}
                          >
                            Pridať riadok +
                          </div>
                        </div>
                      );
                    })}{" "}
                    {vsetkyUdaje.tabulka3.map((hodnotaArray, indexTabulky) => {
                      return (
                        <div
                          id={"tretia" + indexTabulky}
                          className="tretia_tabulka"
                        >
                          <div className="cele_nazvy">
                            {hodnotaArray.nazvy.map((group, indexNazov) => {
                              if (Array.isArray(group)) {
                                let pocet_stlpcov =
                                  group[group.length - 1].length;
                                return (
                                  <div
                                    key={indexNazov}
                                    className={"group flex" + pocet_stlpcov}
                                  >
                                    {group.map((row, rowIndex) => (
                                      <div key={rowIndex} className="row">
                                        {row.map((name, nameIndex) => (
                                          <div
                                            className="element_nazov"
                                            key={nameIndex}
                                          >
                                            {name}
                                          </div>
                                        ))}
                                      </div>
                                    ))}
                                  </div>
                                );
                              } else {
                                return (
                                  <div className="group" key={indexNazov}>
                                    <div className="row">
                                      <input
                                        className="element_nazov_edit"
                                        placeholder="..."
                                        value={group}
                                        onChange={(e) =>
                                          handleNazovChange(
                                            3,
                                            e.target.value,
                                            indexTabulky,
                                            indexNazov
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            })}

                            <div className="group">
                              <div className="row">
                                <div className="element_nazov">↓</div>
                              </div>
                            </div>
                          </div>

                          <div className="table-content">
                            {" "}
                            {hodnotaArray.hodnoty.map(
                              (hodnota, indexRiadka) => {
                                let indexy = 3;

                                return (
                                  <div className="edit-row-cely">
                                    <div
                                      key={indexy.toString() + indexRiadka}
                                      className="table-row-edit"
                                    >
                                      {hodnota.map(
                                        (hodnotaRiadok, indexHodnoty) => {
                                          return (
                                            <input
                                              className="table-data-edit"
                                              value={hodnotaRiadok}
                                              placeholder="..."
                                              onChange={(e) =>
                                                handlehodnotaChange(
                                                  3,
                                                  e.target.value,
                                                  indexTabulky,
                                                  indexRiadka,
                                                  indexHodnoty
                                                )
                                              }
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                    <div
                                      className="tlacitko-vymazat"
                                      onClick={() => {
                                        odstranitRiadok(hodnota, 3);
                                      }}
                                    >
                                      x
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div
                            class="tlacitko"
                            style={{ width: "120px" }}
                            onClick={() => pridatRiadok(3)}
                          >
                            Pridať riadok +
                          </div>
                        </div>
                      );
                    })}
                    {vsetkyUdaje.tabulka4.map((hodnotaArray, indexTabulky) => {
                      return (
                        <div
                          id={"stvrta" + indexTabulky}
                          className="stvrta_tabulka"
                        >
                          <div className="cele_nazvy">
                            {hodnotaArray.nazvy.map((group, indexNazov) => {
                              if (Array.isArray(group)) {
                                let pocet_stlpcov =
                                  group[group.length - 1].length;
                                return (
                                  <div
                                    key={indexNazov}
                                    className={"group flex" + pocet_stlpcov}
                                  >
                                    {group.map((row, rowIndex) => (
                                      <div key={rowIndex} className="row">
                                        {row.map((name, nameIndex) => (
                                          <div
                                            className="element_nazov"
                                            key={nameIndex}
                                          >
                                            {name}
                                          </div>
                                        ))}
                                      </div>
                                    ))}
                                  </div>
                                );
                              } else {
                                return (
                                  <div className="group" key={indexNazov}>
                                    <div className="row">
                                      <input
                                        className="element_nazov_edit"
                                        placeholder="..."
                                        value={group}
                                        onChange={(e) =>
                                          handleNazovChange(
                                            4,
                                            e.target.value,
                                            indexTabulky,
                                            indexNazov
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            })}

                            <div className="group">
                              <div className="row">
                                <div className="element_nazov">↓</div>
                              </div>
                            </div>
                          </div>
                          <div className="table-content">
                            {" "}
                            {hodnotaArray.hodnoty.map(
                              (hodnota, indexRiadka) => {
                                let indexy = 4;

                                return (
                                  <div className="edit-row-cely">
                                    <div
                                      key={indexy.toString() + indexRiadka}
                                      className="table-row-edit"
                                    >
                                      {hodnota.map(
                                        (hodnotaRiadok, indexHodnoty) => {
                                          return (
                                            <input
                                              className="table-data-edit"
                                              value={hodnotaRiadok}
                                              placeholder="..."
                                              onChange={(e) =>
                                                handlehodnotaChange(
                                                  4,
                                                  e.target.value,
                                                  indexTabulky,
                                                  indexRiadka,
                                                  indexHodnoty
                                                )
                                              }
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                    <div
                                      className="tlacitko-vymazat"
                                      onClick={() => {
                                        odstranitRiadok(hodnota, 4);
                                      }}
                                    >
                                      x
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div
                            class="tlacitko"
                            style={{ width: "120px" }}
                            onClick={() => pridatRiadok(4)}
                          >
                            Pridať riadok +
                          </div>
                        </div>
                      );
                    })}
                    {vsetkyUdaje.tabulka5.map((hodnotaArray, indexTabulky) => {
                      return (
                        <div
                          id={"piata" + indexTabulky}
                          className="piata_tabulka"
                        >
                          <div className="cele_nazvy">
                            {hodnotaArray.nazvy.map((group, indexNazov) => {
                              if (Array.isArray(group)) {
                                let pocet_stlpcov =
                                  group[group.length - 1].length;
                                return (
                                  <div
                                    key={indexNazov}
                                    className={"group flex" + pocet_stlpcov}
                                  >
                                    {group.map((row, rowIndex) => (
                                      <div key={rowIndex} className="row">
                                        {row.map((name, nameIndex) => (
                                          <div
                                            className="element_nazov"
                                            key={nameIndex}
                                          >
                                            {name}
                                          </div>
                                        ))}
                                      </div>
                                    ))}
                                  </div>
                                );
                              } else {
                                return (
                                  <div className="group" key={indexNazov}>
                                    <div className="row">
                                      <input
                                        className="element_nazov_edit"
                                        placeholder="..."
                                        value={group}
                                        onChange={(e) =>
                                          handleNazovChange(
                                            5,
                                            e.target.value,
                                            indexTabulky,
                                            indexNazov
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            })}

                            <div className="group">
                              <div className="row">
                                <div className="element_nazov">↓</div>
                              </div>
                            </div>
                          </div>
                          <div className="table-content">
                            {" "}
                            {hodnotaArray.hodnoty.map(
                              (hodnota, indexRiadka) => {
                                let indexy = 5;

                                return (
                                  <div className="edit-row-cely">
                                    <div
                                      key={indexy.toString() + indexRiadka}
                                      className="table-row-edit"
                                    >
                                      {hodnota.map(
                                        (hodnotaRiadok, indexHodnoty) => {
                                          return (
                                            <input
                                              className="table-data-edit"
                                              value={hodnotaRiadok}
                                              placeholder="..."
                                              onChange={(e) =>
                                                handlehodnotaChange(
                                                  5,
                                                  e.target.value,
                                                  indexTabulky,
                                                  indexRiadka,
                                                  indexHodnoty
                                                )
                                              }
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                    <div
                                      className="tlacitko-vymazat"
                                      onClick={() => {
                                        odstranitRiadok(hodnota, 5);
                                      }}
                                    >
                                      x
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div
                            class="tlacitko"
                            style={{ width: "120px" }}
                            onClick={() => pridatRiadok(5)}
                          >
                            Pridať riadok +
                          </div>
                        </div>
                      );
                    })}
                    {vsetkyUdaje.tabulka6.map((hodnotaArray, indexTabulky) => {
                      return (
                        <div
                          id={"siesta" + indexTabulky}
                          className="siesta_tabulka"
                        >
                          <div className="cele_nazvy">
                            {hodnotaArray.nazvy.map((group, indexNazov) => {
                              if (Array.isArray(group)) {
                                let pocet_stlpcov =
                                  group[group.length - 1].length;
                                return (
                                  <div
                                    key={indexNazov}
                                    className={"group flex" + pocet_stlpcov}
                                  >
                                    {group.map((row, rowIndex) => (
                                      <div key={rowIndex} className="row">
                                        {row.map((name, nameIndex) => (
                                          <div
                                            className="element_nazov"
                                            key={nameIndex}
                                          >
                                            {name}
                                          </div>
                                        ))}
                                      </div>
                                    ))}
                                  </div>
                                );
                              } else {
                                return (
                                  <div className="group" key={indexNazov}>
                                    <div className="row">
                                      <input
                                        className="element_nazov_edit"
                                        placeholder="..."
                                        value={group}
                                        onChange={(e) =>
                                          handleNazovChange(
                                            6,
                                            e.target.value,
                                            indexTabulky,
                                            indexNazov
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            })}

                            <div className="group">
                              <div className="row">
                                <div className="element_nazov">↓</div>
                              </div>
                            </div>
                          </div>
                          <div className="table-content">
                            {" "}
                            {hodnotaArray.hodnoty.map(
                              (hodnota, indexRiadka) => {
                                let indexy = 6;

                                return (
                                  <div className="edit-row-cely">
                                    <div
                                      key={indexy.toString() + indexRiadka}
                                      className="table-row-edit"
                                    >
                                      {hodnota.map(
                                        (hodnotaRiadok, indexHodnoty) => {
                                          return (
                                            <input
                                              className="table-data-edit"
                                              value={hodnotaRiadok}
                                              placeholder="..."
                                              onChange={(e) =>
                                                handlehodnotaChange(
                                                  6,
                                                  e.target.value,
                                                  indexTabulky,
                                                  indexRiadka,
                                                  indexHodnoty
                                                )
                                              }
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                    <div
                                      className="tlacitko-vymazat"
                                      onClick={() => {
                                        odstranitRiadok(hodnota, 6);
                                      }}
                                    >
                                      x
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <div
                            class="tlacitko"
                            style={{ width: "120px", marginBottom: "10px" }}
                            onClick={() => pridatRiadok(6)}
                          >
                            Pridať riadok +
                          </div>
                        </div>
                      );
                    })}
                    <div className="siedma_tabulka">
                      <div className="table-header">
                        {vsetkyUdaje.tabulka7.nazvy.map((nazov, i) => {
                          return (
                            <div key={"6" + i} className="siedma_item">
                              {nazov}
                            </div>
                          );
                        })}
                        <div className="siedma_item">↓</div>
                        <div className="siedma_item">↓</div>
                      </div>
                      <div className="table-content">
                        {" "}
                        {vsetkyUdaje.tabulka7.hodnoty.map((hodnota, index) => {
                          let indexy = 8;
                          let pismenko = hodnota[0];
                          return (
                            <div
                              key={indexy.toString() + index}
                              className="nestisknute table-row-siedma"
                            >
                              {hodnota.map((hodnotaRiadok, i) => {
                                return (
                                  <div className="siedma_data">
                                    {hodnotaRiadok}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                        <div className="dov_kombinacie ">
                          <div id="kombinacie">
                            Dovolené kombinácie a kódy:{" "}
                            {Object.keys(vsetkyUdaje.tabulka7.kombinacie).map(
                              (elem, i) => {
                                if (elem.length > 1) {
                                  return (
                                    elem +
                                    "=" +
                                    vsetkyUdaje.tabulka7.kombinacie[elem] +
                                    " "
                                  );
                                  //   elem.split("").join("+") +
                                  //   "=" +
                                  //   kombinacieSpolu[elem] +
                                  //   " | "
                                }
                                return null;
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="nazov_obrazok">
                  <div className="detail">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex", gap: "20px" }}>
                        <TextField
                          label="Typové"
                          variant="standard"
                          style={{ width: "60px", marginBottom: "10px" }}
                          value={vsetkyUdaje.kod}
                          placeholder="typové"
                          onChange={(e) =>
                            setVsetkyUdaje({
                              ...vsetkyUdaje,
                              kod: e.target.value,
                            })
                          }
                        />
                        <div>
                          <Box
                            sx={{ minWidth: 200 }}
                            style={{ margin: "10px 0 " }}
                          >
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label1">
                                Kategória
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label1"
                                value={vsetkyUdaje.kategoria}
                                label="Kategória"
                                onChange={(e) => {
                                  setVsetkyUdaje({
                                    ...vsetkyUdaje,
                                    kategoria: e.target.value,
                                  });
                                }}
                              >
                                <MenuItem value={"standard"}>Standard</MenuItem>
                                <MenuItem value={"standard-ex"}>
                                  Standard - Ex
                                </MenuItem>
                                <MenuItem value={"rematic"}>Rematic</MenuItem>
                                <MenuItem value={"rematic-ex"}>
                                  Rematic - Ex
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Box>

                          <Box
                            sx={{ minWidth: 200 }}
                            style={{ margin: "10px 0 " }}
                          >
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label2">
                                Otáčky
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label2"
                                value={vsetkyUdaje.otacky}
                                label="Otáčky"
                                onChange={(e) => {
                                  setVsetkyUdaje({
                                    ...vsetkyUdaje,
                                    otacky: e.target.value,
                                  });
                                }}
                              >
                                <MenuItem value={"jedno"}>
                                  Jednootáčkové
                                </MenuItem>
                                <MenuItem value={"viac"}>Viacotáčkové</MenuItem>

                                <MenuItem value={"priamo"}>
                                  Priamočiare
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </div>
                      </div>

                      <TextField
                        variant="outlined"
                        id="nazov"
                        value={vsetkyUdaje.nazov}
                        // placeholder="Názov"
                        onChange={(e) =>
                          setVsetkyUdaje({
                            ...vsetkyUdaje,
                            nazov: e.target.value,
                          })
                        }
                        style={{ marginBottom: "6px" }}
                      />
                    </div>

                    <div id="vybavenia_obvidva" style={{ marginTop: "6px" }}>
                      <div>
                        <h2
                          className="vybavenia_nazvy"
                          style={{ marginBottom: "10px" }}
                        >
                          Štandardné vybavenie:
                        </h2>
                        {vsetkyUdaje.vybavenie.standardne.length > 0 && (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {" "}
                            {vsetkyUdaje.vybavenie.standardne.map((elem, i) => {
                              return (
                                <div style={{ height: "24px" }}>
                                  <input
                                    className="vybavenie-edit"
                                    style={{ height: "24px" }}
                                    key={i + "s"}
                                    value={elem}
                                    onChange={(e) => {
                                      let zmena = [
                                        ...vsetkyUdaje.vybavenie.standardne,
                                      ];
                                      zmena[i] = e.target.value;
                                      if (!zmena.includes("")) {
                                        zmena = [...zmena, ""];
                                      }
                                      setVsetkyUdaje({
                                        ...vsetkyUdaje,
                                        vybavenie: {
                                          ...vsetkyUdaje.vybavenie,
                                          standardne: zmena,
                                        },
                                      });
                                    }}
                                  ></input>
                                  <button
                                    hidden={
                                      vsetkyUdaje.vybavenie.standardne.length -
                                        1 ===
                                      i
                                        ? true
                                        : false
                                    }
                                    className="tlacitko-vymazat-male"
                                    style={{ marginLeft: "5px" }}
                                    onClick={(e) => {
                                      let zmena = [
                                        ...vsetkyUdaje.vybavenie.standardne,
                                      ];
                                      zmena = zmena.filter(
                                        (e, index) => i !== index
                                      );
                                      setVsetkyUdaje({
                                        ...vsetkyUdaje,
                                        vybavenie: {
                                          ...vsetkyUdaje.vybavenie,
                                          standardne: zmena,
                                        },
                                      });
                                    }}
                                  >
                                    x
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>

                      <div>
                        <h2 className="vybavenia_nazvy">
                          Rozšírené vybavenie:
                        </h2>
                        {vsetkyUdaje.vybavenie.rozsirene.length > 0 && (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {" "}
                            {vsetkyUdaje.vybavenie.rozsirene.map((elem, i) => {
                              return (
                                <div style={{ height: "24px" }}>
                                  <input
                                    className="vybavenie-edit"
                                    style={{ height: "24px" }}
                                    key={i + "r"}
                                    value={elem}
                                    onChange={(e) => {
                                      let zmena = [
                                        ...vsetkyUdaje.vybavenie.rozsirene,
                                      ];
                                      zmena[i] = e.target.value;
                                      if (!zmena.includes("")) {
                                        zmena = [...zmena, ""];
                                      }
                                      setVsetkyUdaje({
                                        ...vsetkyUdaje,
                                        vybavenie: {
                                          ...vsetkyUdaje.vybavenie,
                                          rozsirene: zmena,
                                        },
                                      });
                                    }}
                                  ></input>
                                  <button
                                    hidden={
                                      vsetkyUdaje.vybavenie.rozsirene.length -
                                        1 ===
                                      i
                                        ? true
                                        : false
                                    }
                                    className="tlacitko-vymazat-male"
                                    style={{ marginLeft: "5px" }}
                                    onClick={(e) => {
                                      let zmena = [
                                        ...vsetkyUdaje.vybavenie.rozsirene,
                                      ];
                                      zmena = zmena.filter(
                                        (e, index) => i !== index
                                      );
                                      setVsetkyUdaje({
                                        ...vsetkyUdaje,
                                        vybavenie: {
                                          ...vsetkyUdaje.vybavenie,
                                          rozsirene: zmena,
                                        },
                                      });
                                    }}
                                  >
                                    x
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="board lavy_margin-konfi">
                  <div className="container">
                    {classyTabulky.map((e, i) => {
                      return (
                        <EditTabulka
                          tabulka={vsetkyUdaje["tabulka" + (i + 1).toString()]}
                          cisloHlavnejTab={i + 1}
                          mazanie={mazanie}
                          stlpce={stlpce}
                          updateVsetkyUdaje={updateVsetkyUdaje}
                          classPreTabulka={e}
                          vsetkyUdaje={vsetkyUdaje}
                          presuvanie={presuvanie}
                        />
                      );
                    })}
                    {vsetkyUdaje.tabulka7 ? (
                      <div className="siedma_tabulka">
                        <div className="table-header">
                          {vsetkyUdaje.tabulka7.nazvy.map((nazov, i) => {
                            return (
                              <input
                                key={"6" + i}
                                className="siedma_item-edit"
                                value={nazov}
                                onChange={(e) => {
                                  let novaNazovHodnota =
                                    vsetkyUdaje.tabulka7.nazvy;

                                  novaNazovHodnota[i] = e.target.value;
                                  setVsetkyUdaje({
                                    ...vsetkyUdaje,
                                    tabulka7: {
                                      ...vsetkyUdaje.tabulka7,
                                      nazvy: novaNazovHodnota,
                                    },
                                  });
                                }}
                              ></input>
                            );
                          })}
                          <div className="siedma_item">↓</div>
                          <div className="siedma_item">↓</div>
                        </div>
                        <div className="table-content">
                          {" "}
                          {vsetkyUdaje.tabulka7.hodnoty.map(
                            (hodnota, indexRiadku) => {
                              let indexy = 8;
                              // let pismenko = hodnota[0];
                              return (
                                <div
                                  key={indexy.toString() + indexRiadku}
                                  className="nestisknute table-row-siedma-edit"
                                >
                                  {hodnota.map(
                                    (jednaHodnota, indexJednejHodnoty) => {
                                      return (
                                        <input
                                          className="siedma_data-edit"
                                          value={jednaHodnota}
                                          onChange={(e) => {
                                            let novaHodnota =
                                              vsetkyUdaje.tabulka7.hodnoty;

                                            novaHodnota[indexRiadku][
                                              indexJednejHodnoty
                                            ] = e.target.value;
                                            setVsetkyUdaje({
                                              ...vsetkyUdaje,
                                              tabulka7: {
                                                ...vsetkyUdaje.tabulka7,
                                                hodnoty: novaHodnota,
                                              },
                                            });
                                          }}
                                        ></input>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            }
                          )}
                          <h2>Pridávanie kombinácii</h2>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "2px",
                              marginTop: "14px",
                              marginBottom: "14px",
                            }}
                          >
                            <TextField
                              id="outlined-basic"
                              label="Kód"
                              variant="outlined"
                              type="text"
                              onChange={(e) =>
                                setKombinaciaKey(e.target.value.toUpperCase())
                              }
                              value={kombinaciaKey}
                              size="small"
                              style={{
                                width: "80px",
                              }}
                              inputProps={{ maxLength: 4 }}
                            />
                            <div> =</div>
                            <TextField
                              id="outlined-basic"
                              label="Kombi."
                              variant="outlined"
                              type="text"
                              onChange={(e) =>
                                setKombinaciaValue(e.target.value.toUpperCase())
                              }
                              value={kombinaciaValue}
                              size="small"
                              style={{
                                width: "80px",
                              }}
                              inputProps={{ maxLength: 4 }}
                            />
                            <Button
                              style={{ marginLeft: "10px" }}
                              variant="contained"
                              onClick={() => {
                                if (kombinaciaKey.length > 1) {
                                  const noveKombinacie = Object.entries({
                                    ...vsetkyUdaje.tabulka7.kombinacie,
                                    [kombinaciaKey]: kombinaciaValue,
                                  });

                                  noveKombinacie.sort((a, b) => a[1] - b[1]);

                                  const sortedKombinacie =
                                    Object.fromEntries(noveKombinacie);

                                  setVsetkyUdaje({
                                    ...vsetkyUdaje,
                                    tabulka7: {
                                      ...vsetkyUdaje.tabulka7,
                                      kombinacie: sortedKombinacie,
                                    },
                                  });
                                  setKombinaciaKey("");
                                  setKombinaciaValue("");
                                }
                              }}
                            >
                              Pridať
                            </Button>
                          </div>
                          <div className="dov_kombinacie ">
                            <div id="kombinacie">
                              Dovolené kombinácie a kódy:{" "}
                              <Stack
                                direction="row"
                                spacing={1}
                                style={{ marginTop: "5px" }}
                                flexWrap="wrap"
                                useFlexGap
                              >
                                {vsetkyUdaje.tabulka7.kombinacie
                                  ? Object.keys(
                                      vsetkyUdaje.tabulka7.kombinacie
                                    ).map((elem, i) => {
                                      if (elem.length > 1) {
                                        return (
                                          <Chip
                                            variant="outlined"
                                            label={
                                              elem.split("").join("+") +
                                              "=" +
                                              vsetkyUdaje.tabulka7.kombinacie[
                                                elem
                                              ]
                                            }
                                            onDelete={() => {
                                              deleteKombinacia(elem);
                                            }}
                                          />
                                        );
                                      }
                                      return null;
                                    })
                                  : ""}
                              </Stack>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            <div className="fixed-bottom-right">
              <div className="tlacitko" onClick={pridatNoveServo}>
                Pridať servo
              </div>
            </div>
          </div>
        </>
      )}

      {!vsetkyUdaje && <Loading />}
      {nacitava && <Loading />}
    </>
  );
};

export default NoveServo;
