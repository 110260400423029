import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Nav from "../components/Nav";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Loading from "../components/LoadingScreen";
import ConfigTabulka from "../components/ConfiguratorComp/ConfigTabulka";
import pdfExport from "../images/exportPDF.svg";
import { Tooltip } from "@mui/material";
import SiedmaTabulkaConfi from "../components/ConfiguratorComp/SiedmaTabConfi";

import Vybavenie from "../components/ConfiguratorComp/Vybavenie";

let initialCounters = ["x", "-", "x", "x", "x", "x", "x", "/"];

const Configurator = () => {
  const serverAdresa = "https://vps.regada.sk/";
  // const serverAdresa = "http://localhost:8000/";
  const { typove } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const prveSpustenie = useRef(false);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  let default_najdene = {
    0: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
  };

  let zakazana_moznost = [];
  let kombinacie = {};
  let kombinacieSpolu = {};
  let kombinaciePismena = [];

  // bez pomlcky
  const default_arr = ["", "", "", "", "", "", "", ""];

  const [najdene, setNajdene] = useState(default_najdene);
  const [najdenePopis, setNajdenePopis] = useState(default_najdene);
  const [stlacene, setStlacene] = useState(initialCounters);
  const [vsetkyUdaje, setVsetkyUdaje] = useState(null);
  const [slovnik, setSlovnik] = useState(null);
  const [pismenka, setPismenka] = useState([]);
  const [nevybrateMoznosti, setNevybrateMoznosti] = useState(false);
  // zmenit jazyk
  const [selectedLanguage, setSelectedLanguage] = useState("SK");
  const [zakaznicke, setZakaznicke] = useState([...default_arr]);
  const [konektor, setKonektor] = useState(false);

  const [dlhsieRozsireneCislo, setDlhsieRozsireneCislo] = useState(false);

  const [najdenePoznamky, setNajdenePoznamky] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);

  const classyTabulky = [
    "prva",
    "druha",
    "tretia",
    "stvrta",
    "piata",
    "siesta",
  ];

  const [celyRiadok, setCelyRiadok] = useState([[], [], [], [], [], [], []]);
  const [ktoryNazov, setKtoryNazov] = useState([...default_arr]);
  // v ktorej tabulke hover
  const [ktoraTab, setKtoraTab] = useState(null);
  const [naspat, setNaspat] = useState(true);

  const [nacrtky, setNacrtky] = useState([...default_arr]);
  const [schemy, setSchemy] = useState([...default_arr]);
  const [finalSchemy, setFinalSchemy] = useState([...default_arr]);

  const [pdfUrlSchemy, setPdfUrlSchemy] = useState(null);
  const [pdfUrlNacrtky, setPdfUrlpdfUrlNacrtky] = useState(null);
  const [pdfKatalog, setPdfKatalog] = useState(null);
  const [nacitava, setNacitava] = useState(false);

  let poznamka = {};

  const handleBeforeUnload = (event) => {
    event.preventDefault();

    const confirmationMessage = "Ste si istý že chcete opustiť stránku ?";

    event.returnValue = confirmationMessage;

    return confirmationMessage;
  };

  if (location.state && naspat) {
    setPismenka(location.state.pismenka);
    setStlacene(location.state.stlacene);
    setSelectedLanguage(location.state.selectedLanguage);
    setZakaznicke(location.state.zakaznicke);
    setCelyRiadok(location.state.celyRiadok);
    setKtoryNazov(location.state.ktoryNazov);
    setNacrtky(location.state.nacrtky);
    setSchemy(location.state.schemy);
    setNajdene(location.state.najdene);
    setNajdenePopis(location.state.najdenePopis);
    setNajdenePoznamky(location.state.najdenePoznamky);
    setDlhsieRozsireneCislo(dlhsieRozsireneCislo);
    setNaspat(false);
  }

  // viac calls na servo
  async function getServoWithRetry() {
    let maxRetries = 3; // Set the maximum number of retries
    let currentRetry = 0;

    while (currentRetry < maxRetries) {
      try {
        await getServo(); // Your modified function
        return; // Exit the loop if successful
      } catch (error) {
        currentRetry++;
      }
    }

    console.log("Max retries reached. Unable to fetch data.");
  }

  async function getServo() {
    try {
      const response = await axios.get(`${serverAdresa}standard`, {
        params: { typove },
      });

      const response2 = await axios.get(`${serverAdresa}vseobPoznamky`);

      if (!response.data.poznamka) {
        response.data.poznamka = {};
      }

      for (const key in response2.data[0]) {
        if (!(key in response.data.poznamka)) {
          response.data.poznamka[key] = response2.data[0][key];
        }
      }

      setVsetkyUdaje(response.data);
    } catch (e) {
      console.log(e);
    }
  }

  const getKatalog = async () => {
    setNacitava(true);
    try {
      const response = await axios.post(
        `${serverAdresa}generateKatalog`,
        {
          vsetkyUdaje,
          slovnik,
          selectedLanguage,
          poznamka,
        },
        {
          responseType: "blob", // Specify the response type
        }
      );

      const pdfUrl = URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      setPdfKatalog(pdfUrl);
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
    setNacitava(false);
  };

  async function getSlovnik() {
    try {
      // vseobecnySlovnik
      const response1 = await axios.get(`${serverAdresa}slovnikVseob`);

      // poznamkySlovnik
      const response2 = await axios.get(`${serverAdresa}slovnikPoznamky`);

      setSlovnik({ ...response1.data, ...response2.data });
    } catch (e) {
      console.log(e);
    }
  }

  // zakazane moznosti
  if (vsetkyUdaje) {
    if (vsetkyUdaje.zakazana_moznost) {
      zakazana_moznost = vsetkyUdaje.zakazana_moznost;
    }
    if (vsetkyUdaje.poznamka) {
      poznamka = vsetkyUdaje.poznamka;
    }
  }

  // vsetky kombinacie v rozsierenych
  if (vsetkyUdaje) {
    if (vsetkyUdaje.tabulka7) {
      for (let i = 0; i < vsetkyUdaje.tabulka7.hodnoty.length; i++) {
        let jeden_riadok = vsetkyUdaje.tabulka7.hodnoty[i];

        kombinacie[jeden_riadok[0]] =
          jeden_riadok[jeden_riadok.length - 2] +
          jeden_riadok[jeden_riadok.length - 1];
      }

      kombinacieSpolu = {
        ...kombinacie,
        ...vsetkyUdaje.tabulka7.kombinacie,
      };
      kombinaciePismena = Object.keys(kombinacieSpolu);
    }
  }

  useEffect(() => {
    if (prveSpustenie.current === false) {
      getServoWithRetry();
      getSlovnik();
      return () => (prveSpustenie.current = true);
    }
  }, []);

  // useEffect(() => {
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  // NEVYBRATY MOD

  if (
    nevybrateMoznosti &&
    stlacene.filter((e) => e === "x").length === 0 &&
    !stlacene.includes("X")
  ) {
    navigate("/nahlad", {
      state: {
        stlacene,
        typove,
        vsetkyUdaje,
        slovnik,
        selectedLanguage,
        pismenka,
        kombinacieSpolu,
        kombinaciePismena,
        zakaznicke,
        celyRiadok,
        ktoryNazov,
        nacrtky,
        schemy,
        najdene,
        najdenePopis,
        poznamka,
        najdenePoznamky,
        dlhsieRozsireneCislo,
      },
    });
  }

  const Rozsirene = (letters) => {
    // nieco vybrate
    if (letters.letters.length > 0) {
      if (kombinacieSpolu.hasOwnProperty(letters.letters.sort().join(""))) {
        let cislo_k_tomu = kombinacieSpolu[letters.letters.sort().join("")];
        if (cislo_k_tomu.length > 2) {
          setDlhsieRozsireneCislo(cislo_k_tomu.length);
        } else {
          setDlhsieRozsireneCislo(false);
        }

        return cislo_k_tomu
          .split("")
          .map((jednoRozsirene, jednoRozsireneIndex) => {
            return (
              <div
                className="objednavaci_riadok cislo"
                key={jednoRozsireneIndex + jednoRozsirene}
              >
                {jednoRozsirene}
              </div>
            );
          });

        // return (
        //   <>
        //     <div className="objednavaci_riadok cislo">{cislo_k_tomu[0]}</div>
        //     <div className="objednavaci_riadok cislo">{cislo_k_tomu[1]}</div>
        //   </>
        // );
      } else {
        return (
          <>
            <div className="objednavaci_riadok cislo">-</div>
            <div className="objednavaci_riadok cislo">-</div>
          </>
        );
      }
    }
    // prazdne rozsirene
    else {
      return (
        <>
          <div className="objednavaci_riadok cislo">x</div>
          <div className="objednavaci_riadok cislo">x</div>
        </>
      );
    }
  };

  // CALLBACKS PRE COMPONENTY

  const updateKtoraTab = (newKtoraTab) => {
    setKtoraTab(newKtoraTab);
  };

  const updateSchemy = (newSchemy) => {
    setSchemy(newSchemy);
  };

  const updateNacrtky = (newNacrtky) => {
    setNacrtky(newNacrtky);
  };

  const updateKtoryNazov = (newKtoryNazov) => {
    setKtoryNazov(newKtoryNazov);
  };

  const updateFinalSchemy = (newFinalSchemy) => {
    setFinalSchemy(newFinalSchemy);
  };

  const updateNajdenePoznamky = (newNajdenePoznamky) => {
    setNajdenePoznamky(newNajdenePoznamky);
  };

  const updateKonektor = (newKonektor) => {
    setKonektor(newKonektor);
  };

  const updateCelyRiadok = (newCelyRiadok) => {
    setCelyRiadok(newCelyRiadok);
  };

  const updateStlacene = (newStlacene) => {
    setStlacene(newStlacene);
  };

  const updateNajdene = (newNajdene) => {
    setNajdene(newNajdene);
  };

  const updateNajdenePopis = (newNajdenePopis) => {
    setNajdenePopis(newNajdenePopis);
  };

  const updateZakaznicke = (newZakaznicke) => {
    setZakaznicke(newZakaznicke);
  };

  const nastavitKtoraTab = (newKtoraTab) => {
    setKtoraTab(newKtoraTab);
  };

  const nastavitPismenka = (newPismenka) => {
    setPismenka(newPismenka);
  };

  const nastavitFinalSchemy = (newFinalSchemy) => {
    setFinalSchemy(newFinalSchemy);
  };

  // SCHEMY PDF

  const fetchPDF = async (typePdf) => {
    setNacitava(true);
    const prva = "Z1a";
    const druha = "Z11a";
    const tretia = "Z269r";
    try {
      const response = await axios.get(`${serverAdresa}${typePdf}`, {
        responseType: "blob",
        params: {
          typove,
          prva,
          druha,
          tretia,
        },
      });

      const pdfUrl = URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      if (typePdf === "generateSchemy") {
        setPdfUrlSchemy(pdfUrl);
      } else if (typePdf === "generateNacrtky") {
        setPdfUrlpdfUrlNacrtky(pdfUrl);
      }
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
    setNacitava(false);
  };

  const handleDownloadPDF = async (ktoryPdf) => {
    await fetchPDF(ktoryPdf); // Fetch the PDF from the backend
  };

  useEffect(() => {
    if (pdfUrlSchemy) {
      const link = document.createElement("a");
      link.href = pdfUrlSchemy;
      // link.download = today.toDateString(); // Set the suggested filename
      link.download = "schemy_regada_" + typove + "_" + vsetkyUdaje.nazov;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [pdfUrlSchemy]);

  useEffect(() => {
    if (pdfUrlNacrtky) {
      const link = document.createElement("a");
      link.href = pdfUrlNacrtky;
      // link.download = today.toDateString(); // Set the suggested filename
      link.download = "nacrty_regada_" + typove + "_" + vsetkyUdaje.nazov;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [pdfUrlNacrtky]);

  useEffect(() => {
    if (pdfKatalog) {
      const link = document.createElement("a");
      link.href = pdfKatalog;
      // link.download = today.toDateString(); // Set the suggested filename
      link.download = vsetkyUdaje.nazov + "_katalog";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [pdfKatalog]);

  return (
    <>
      {vsetkyUdaje && slovnik && !nacitava && (
        <>
          <Nav
            onSelectLanguage={handleLanguageChange}
            defaultLang={selectedLanguage}
            presmerovanie={"https://www.regada.sk/elektricke-servopohony"}
          />
          {/* FOR TRANSLATING !!! */}
          {/* <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            {Object.values(vsetkyUdaje.poznamka).map((e) => {
              if (!slovnik.hasOwnProperty(e)) {
                return <div>{e}</div>;
              }
            })}
          </div>
          {vsetkyUdaje.otacky} {vsetkyUdaje.kategoria} */}
          <div className="obalena_tab1">
            <div className="nazov_obrazok">
              <div className="detail">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <h1 id="nazov">{vsetkyUdaje.nazov}</h1>
                  {/* Katalog export */}
                  {/* <Tooltip
                    title={
                      selectedLanguage === "EN"
                        ? "Catalog export"
                        : selectedLanguage === "RU"
                        ? "Экспорт каталога"
                        : "Katalóg export"
                    }
                  >
                    <img
                      style={{
                        width: "24px",
                        marginLeft: "20px",
                        cursor: "pointer",
                      }}
                      src={pdfExport}
                      alt="export"
                      onClick={() => {
                        getKatalog();
                      }}
                    ></img>
                  </Tooltip> */}
                </div>

                <div id="vybavenia_obvidva">
                  <div hidden={nevybrateMoznosti} className="vybavenia_jedno">
                    <h2 className="vybavenia_nazvy">
                      {" "}
                      {selectedLanguage === "EN"
                        ? "Standard equipment:"
                        : selectedLanguage === "RU"
                        ? "Стандартное оснащение:"
                        : "Štandardné vybavenie:"}
                    </h2>
                    <ul>
                      {" "}
                      <Vybavenie
                        vybaveniaData={vsetkyUdaje.vybavenie.standardne}
                        slovnik={slovnik}
                        selectedLanguage={selectedLanguage}
                        poznamka={poznamka}
                      />
                    </ul>
                  </div>

                  <div hidden={nevybrateMoznosti}>
                    <h2 className="vybavenia_nazvy">
                      {selectedLanguage === "EN"
                        ? "Additional equipment"
                        : selectedLanguage === "RU"
                        ? "Добавочное оснащение"
                        : "Rozšírené vybavenie:"}
                    </h2>

                    <ul>
                      <Vybavenie
                        vybaveniaData={vsetkyUdaje.vybavenie.rozsirene}
                        slovnik={slovnik}
                        selectedLanguage={selectedLanguage}
                        poznamka={poznamka}
                      />
                    </ul>
                  </div>
                </div>
                <div className="nezvolene_tabulky" hidden={!nevybrateMoznosti}>
                  <div
                    hidden={
                      stlacene.filter((e) => e === "x").length === 0
                        ? true
                        : false
                    }
                  >
                    {selectedLanguage === "EN"
                      ? "You didn't choose from the following tables:"
                      : selectedLanguage === "RU"
                      ? "Вы не выбрали из следующих таблиц:"
                      : "Nezvolili ste si možnosť z následujúcich tabuliek:"}
                  </div>

                  {stlacene
                    .filter((znak) => znak !== "-")
                    .map((e, i) => {
                      if (e === "x") {
                        return (
                          <div>
                            {i + 1}.{" "}
                            {Array.isArray(
                              vsetkyUdaje["tabulka" + (i + 1)][0]["nazvy"][0]
                            )
                              ? slovnik.hasOwnProperty(
                                  vsetkyUdaje["tabulka" + (i + 1)][0][
                                    "nazvy"
                                  ][0][0]
                                )
                                ? selectedLanguage === "EN"
                                  ? slovnik[
                                      vsetkyUdaje["tabulka" + (i + 1)][0][
                                        "nazvy"
                                      ][0][0]
                                    ][0]
                                  : selectedLanguage === "RU"
                                  ? slovnik[
                                      vsetkyUdaje["tabulka" + (i + 1)][0][
                                        "nazvy"
                                      ][0][0]
                                    ][1]
                                  : vsetkyUdaje["tabulka" + (i + 1)][0][
                                      "nazvy"
                                    ][0][0]
                                : vsetkyUdaje["tabulka" + (i + 1)][0][
                                    "nazvy"
                                  ][0][0]
                              : slovnik.hasOwnProperty(
                                  vsetkyUdaje["tabulka" + (i + 1)][0][
                                    "nazvy"
                                  ][0]
                                )
                              ? selectedLanguage === "EN"
                                ? slovnik[
                                    vsetkyUdaje["tabulka" + (i + 1)][0][
                                      "nazvy"
                                    ][0]
                                  ][0]
                                : selectedLanguage === "RU"
                                ? slovnik[
                                    vsetkyUdaje["tabulka" + (i + 1)][0][
                                      "nazvy"
                                    ][0]
                                  ][1]
                                : vsetkyUdaje["tabulka" + (i + 1)][0][
                                    "nazvy"
                                  ][0]
                              : vsetkyUdaje["tabulka" + (i + 1)][0]["nazvy"][0]}
                          </div>
                        );
                      }
                      return null;
                    })}
                </div>
              </div>

              <img
                className="hlavny_obrazok pravy_margin-konfi"
                src={`https://konfigurator.regada.sk/obrazkyVsetky/servopohony/${vsetkyUdaje.kod}.png`}
                alt={vsetkyUdaje.nazov}
              />
            </div>
            <div className="board lavy_margin-konfi">
              <div id="objednavaci_kod">
                <div className="objednavaci_riadok" id="order_code_label">
                  {selectedLanguage === "EN"
                    ? "Order code"
                    : selectedLanguage === "RU"
                    ? "Марка исполнения"
                    : "Objednávací kód"}
                </div>
                <div className="prava_strana">
                  <div id="kodmaly" className="objednavaci_riadok">
                    {typove.length > 3
                      ? typove.slice(0, 2) + " " + typove.slice(2)
                      : typove}
                    .
                  </div>
                  <div className="kod_cisla">
                    {" "}
                    {stlacene.map((ciselo) => {
                      return (
                        <div className="objednavaci_riadok cislo">{ciselo}</div>
                      );
                    })}
                  </div>
                  <Rozsirene letters={pismenka} />
                </div>
              </div>

              <div className="mobile_objednavaci">
                <div className="prava_strana">
                  {typove}.{stlacene}
                  <Rozsirene letters={pismenka} />
                </div>
              </div>

              <div className="container">
                {classyTabulky.map((e, i) => {
                  return (
                    <ConfigTabulka
                      tabulka={vsetkyUdaje["tabulka" + (i + 1).toString()]}
                      cisloHlavnejTab={i === 0 ? 0 : i + 1}
                      idPreTabulku={e}
                      classPreTabulka={
                        dlhsieRozsireneCislo === 3
                          ? e + "_tabulka-3"
                          : e + "_tabulka"
                      }
                      stlacene={stlacene}
                      slovnik={slovnik}
                      selectedLanguage={selectedLanguage}
                      nevybrateMoznosti={nevybrateMoznosti}
                      najdene={najdene}
                      najdenePopis={najdenePopis}
                      poznamka={poznamka}
                      updateKtoraTab={updateKtoraTab}
                      celyRiadok={celyRiadok}
                      ktoryNazov={ktoryNazov}
                      nacrtky={nacrtky}
                      schemy={schemy}
                      vsetkyUdaje={vsetkyUdaje}
                      finalSchemy={finalSchemy}
                      konektor={konektor}
                      updateSchemy={updateSchemy}
                      updateNacrtky={updateNacrtky}
                      updateKtoryNazov={updateKtoryNazov}
                      updateFinalSchemy={updateFinalSchemy}
                      updateNajdenePoznamky={updateNajdenePoznamky}
                      updateKonektor={updateKonektor}
                      updateCelyRiadok={updateCelyRiadok}
                      updateStlacene={updateStlacene}
                      najdenePoznamky={najdenePoznamky}
                      zakazana_moznost={zakazana_moznost}
                      updateNajdenePopis={updateNajdenePopis}
                      updateNajdene={updateNajdene}
                      zakaznicke={zakaznicke}
                      updateZakaznicke={updateZakaznicke}
                    />
                  );
                })}
                {vsetkyUdaje.tabulka7 ? (
                  <SiedmaTabulkaConfi
                    nevybrateMoznosti={nevybrateMoznosti}
                    vsetkyUdaje={vsetkyUdaje}
                    slovnik={slovnik}
                    selectedLanguage={selectedLanguage}
                    pismenka={pismenka}
                    kombinaciePismena={kombinaciePismena}
                    najdene={najdene}
                    kombinacieSpolu={kombinacieSpolu}
                    updateKtoraTab={updateKtoraTab}
                    nastavitPismenka={nastavitPismenka}
                    konektor={konektor}
                    poznamka={poznamka}
                    nastavitFinalSchemy={nastavitFinalSchemy}
                  />
                ) : (
                  ""
                )}

                <div
                  className="tlacitko"
                  hidden={!nevybrateMoznosti}
                  onClick={() => setNevybrateMoznosti(false)}
                >
                  {selectedLanguage === "EN"
                    ? "Show all"
                    : selectedLanguage === "RU"
                    ? "Показать все"
                    : "Zobraziť všetko"}
                </div>
              </div>
            </div>

            <div className="fixed-bottom-right hidePhones">
              {/* objednavaci dole */}
              {/* <div className="oknodole">
                <div>
                  <div className="kod_dole">
                    {" "}
                    {selectedLanguage === "EN"
                      ? "Order code"
                      : selectedLanguage === "RU"
                      ? "Марка исполнения"
                      : "Objednávací kód"}
                  </div>
                  <div className="cisla_dole">
                    <div className="dole_objednavaci">
                      {" "}
                      {typove.length > 3
                        ? typove.slice(0, 2) + " " + typove.slice(2)
                        : typove}
                      .
                    </div>
                    {stlacene.map((elem, i) => {
                      return (
                        <>
                          <div>|</div>
                          <div
                            className={
                              i === ktoraTab
                                ? elem === "x" && nevybrateMoznosti
                                  ? "cervene_x pozeras_tabulku dole_objednavaci"
                                  : "pozeras_tabulku dole_objednavaci"
                                : elem === "x" && nevybrateMoznosti
                                ? "cervene_x dole_objednavaci"
                                : "dole_objednavaci"
                            }
                          >
                            {elem}
                          </div>
                        </>
                      );
                    })}
                    <div
                      className={
                        8 === ktoraTab
                          ? "pozeras_tabulku dole_objednavaci"
                          : "dole_objednavaci"
                      }
                    >
                      {pismenka.length === 0
                        ? "xx"
                        : kombinacieSpolu.hasOwnProperty(
                            pismenka.sort().join("")
                          )
                        ? kombinacieSpolu[pismenka.sort().join("")]
                        : "--"}
                    </div>
                  </div>
                </div>
              </div> */}
              {/* nacrty */}
              {/* <div
                className={
                  JSON.stringify(nacrtky) === JSON.stringify(default_arr)
                    ? "oknodole sedive"
                    : "oknodole"
                }
              >
                <div className="obsah_okno_dole">
                  <div className="nacrty_schemy">
                    <div className="kod_dole">
                      {" "}
                      {selectedLanguage === "EN"
                        ? "Selected drawings"
                        : selectedLanguage === "RU"
                        ? "Избранные эскизы"
                        : "Zvolené náčrty"}
                    </div>
                    {JSON.stringify(nacrtky) === JSON.stringify(default_arr) ? (
                      <div className="cisla_dole">
                        {" "}
                        {selectedLanguage === "EN"
                          ? "None"
                          : selectedLanguage === "RU"
                          ? "никто"
                          : "Žiadne"}
                      </div>
                    ) : (
                      <div className="nacrt">{nacrtky}</div>
                    )}
                  </div>

                  <div
                    className={
                      JSON.stringify(nacrtky) === JSON.stringify(default_arr)
                        ? "sedive-tlacitko-male "
                        : "tlacitko-male"
                    }
                    onClick={
                      JSON.stringify(nacrtky) !== JSON.stringify(default_arr)
                        ? () => handleDownloadPDF("generateNacrtky")
                        : () => ""
                    }
                  >
                    {selectedLanguage === "EN"
                      ? "View"
                      : selectedLanguage === "RU"
                      ? "Вид"
                      : "Ukázať"}
                  </div>
                </div>
              </div> */}

              {/* schemy */}

              {/* <div
                className={
                  JSON.stringify(finalSchemy) === JSON.stringify(default_arr)
                    ? "oknodole sedive"
                    : "oknodole"
                }
              >
                <div className="obsah_okno_dole">
                  <div className="nacrty_schemy">
                    <div className="kod_dole">
                      {" "}
                      {selectedLanguage === "EN"
                        ? "Selected diagrams"
                        : selectedLanguage === "RU"
                        ? "Избранные схемы"
                        : "Zvolené schémy"}
                    </div>
                    {JSON.stringify(finalSchemy) ===
                    JSON.stringify(default_arr) ? (
                      <div className="cisla_dole">
                        {" "}
                        {selectedLanguage === "EN"
                          ? "None"
                          : selectedLanguage === "RU"
                          ? "никто"
                          : "Žiadne"}
                      </div>
                    ) : (
                      <div className="nacrt">{finalSchemy.join(" ")}</div>
                    )}
                  </div>

                  <div
                    className={
                      JSON.stringify(schemy) === JSON.stringify(default_arr)
                        ? "sedive-tlacitko-male "
                        : "tlacitko-male"
                    }
                    onClick={
                      JSON.stringify(schemy) !== JSON.stringify(default_arr)
                        ? () => handleDownloadPDF("generateSchemy")
                        : () => ""
                    }
                  >
                    {selectedLanguage === "EN"
                      ? "View"
                      : selectedLanguage === "RU"
                      ? "Вид"
                      : "Ukázať"}
                  </div>
                </div>
              </div> */}

              {/* tlacitko dalej */}
              <div
                hidden={
                  kombinacieSpolu.hasOwnProperty(pismenka.sort().join(""))
                    ? najdene[7].includes(
                        kombinacieSpolu[pismenka.sort().join("")]
                      )
                      ? true
                      : false
                    : pismenka.length === 0
                    ? false
                    : true
                }
                className="tlacitko-konfi"
                onClick={
                  !stlacene.includes("x")
                    ? () => {
                        navigate("/nahlad", {
                          state: {
                            stlacene,
                            typove,
                            vsetkyUdaje,
                            slovnik,
                            selectedLanguage,
                            pismenka,
                            kombinacieSpolu,
                            kombinaciePismena,
                            zakaznicke,
                            celyRiadok,
                            ktoryNazov,
                            nacrtky,
                            schemy,
                            najdene,
                            najdenePopis,
                            poznamka,
                            najdenePoznamky,
                            dlhsieRozsireneCislo,
                          },
                        });
                      }
                    : () => {
                        setNevybrateMoznosti(true);
                        window.scrollTo(0, 0);
                      }
                }
              >
                {selectedLanguage === "EN"
                  ? "Continue to preview"
                  : selectedLanguage === "RU"
                  ? "Продолжить просмотр"
                  : "Pokračovať na náhľad"}
              </div>
              <div
                hidden={
                  kombinacieSpolu.hasOwnProperty(pismenka.sort().join(""))
                    ? najdene[7].includes(
                        kombinacieSpolu[pismenka.sort().join("")]
                      )
                      ? false
                      : true
                    : pismenka.length === 0
                    ? true
                    : false
                }
                className="tlacitko-chyba-konfi"
              >
                {selectedLanguage === "EN"
                  ? "Forbidden combination"
                  : selectedLanguage === "RU"
                  ? "Запрещенная комбинация"
                  : "Zakázaná kombinácia"}
              </div>
            </div>
          </div>
        </>
      )}

      {!(vsetkyUdaje && slovnik) && <Loading />}
      {nacitava && <Loading />}
    </>
  );
};

export default Configurator;
