import { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";

import regada_picture from "../images/logoTYPOGRAPHIAwhite.svg";
import sipka from "../images/icons8-arrow.svg";
import { useLocation, useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";

import Loading from "../components/LoadingScreen";
import VynimkyTabulka from "../components/VynimkyComp/Vynimky_Tabulka";
import OknoEdit from "../components/OknoEdit";

import VynimkyTabulkaSiedma from "../components/VynimkyComp/VynimkyTabulkaSiedma";

import InfoIcon from "@mui/icons-material/Info";

import AlertDialogSlide from "../components/AlertDialogSlide";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import DocumentaciaDialog from "../components/DocumentaciaDialog";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MaxHeightTextarea from "../components/TextArea";
import deleteIcon from "../images/icons8-delete.svg";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

// AZ TRI NA KONCOVKU
let initialCounters = [
  ["x"],
  ["-"],
  ["x"],
  ["x"],
  ["x"],
  ["x"],
  ["x"],
  ["/"],
  ["x"],
];

const classyTabulky = ["prva", "druha", "tretia", "stvrta", "piata", "siesta"];

const Vynimky = () => {
  const serverAdresa = "https://vps.regada.sk/";
  // const serverAdresa = "http://localhost:8000/";

  const navigate = useNavigate();

  let zaheslovane = false;

  const prveSpustenie = useRef(false);

  const { typove } = useParams();
  // const [typoveHandle, setTypoveHandle] = useState("260");
  const [showModal, setShowModal] = useState(false);

  const [stlacene, setStlacene] = useState(initialCounters);
  const [vsetkyUdaje, setVsetkyUdaje] = useState(null);

  const [zakazane_moznosti, setZakazane_moznosti] = useState([]);

  const [pocetPovodnych, setPocetPovodnych] = useState(0);
  const [momentalnyPocetVynimiek, setMomentalnyPocetVynimiek] = useState(0);

  const [viacMoznosti, setViacMoznosti] = useState(null);
  const [error, setError] = useState(null);

  // Create a ref for the Select element

  const [openDocumentacia, setOpenDocumentacia] = useState(false);

  const [nazovVynimky, setNazovVynimky] = useState("");
  const [popisVynimky, setPopisVynimky] = useState("");
  const [cisloVynimky, setCisloVynimky] = useState("");
  const cisloVynimkyRef = useRef("");
  const [open, setOpen] = useState(false);

  const [nacitava, setNacitava] = useState(false);

  const handleTextareaChange = useCallback((event) => {
    setPopisVynimky(event.target.value);
  }, []);

  const updateStlacene = (newStlacene) => {
    setStlacene(newStlacene);
  };

  const updateViacMoznosti = (newViacMoznosti) => {
    setViacMoznosti(newViacMoznosti);
  };

  const handlePopUp = (e) => {
    setOpen(e);
  };

  async function getServo() {
    setNacitava(true);
    try {
      const response = await axios.get(`${serverAdresa}standard`, {
        params: { typove },
      });
      setVsetkyUdaje(response.data);

      if (response.data.zakazana_moznost) {
        if (response.data.zakazana_moznost.length > 0) {
          setZakazane_moznosti(response.data.zakazana_moznost);
          cisloVynimkyRef.current =
            response.data.zakazana_moznost[
              response.data.zakazana_moznost.length - 1
            ].poznamka;

          setCisloVynimky(
            response.data.zakazana_moznost[
              response.data.zakazana_moznost.length - 1
            ].poznamka
          );
          let pocetNaZaciatku = countVynimkyElements(
            response.data.zakazana_moznost
          );
          setPocetPovodnych(pocetNaZaciatku);
          setMomentalnyPocetVynimiek(pocetNaZaciatku);
        }
      } else {
        setZakazane_moznosti([]);
      }

      // zakazana_moznosti
    } catch (e) {
      console.log(e);
    }

    setNacitava(false);
  }

  function countVynimkyElements(data) {
    return data.reduce((accumulator, currentObject) => {
      return accumulator + currentObject.vynimky.length;
    }, 0);
  }

  useEffect(() => {
    if (prveSpustenie.current === false) {
      // do fetch dat aj cislovanie
      getServo();
      return () => (prveSpustenie.current = true);
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        if (zakazane_moznosti.length > 0) {
          pridatVynimku();
        }
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [stlacene]);

  function removeXArrays(arr) {
    for (let i = arr.length - 1; i >= 0; i--) {
      if (
        Array.isArray(arr[i]) &&
        arr[i].length === 1 &&
        (arr[i][0] === "x" || arr[i][0] === "/")
      ) {
        arr.splice(i, 1);
      } else {
        break; // Stop when a different value is encountered
      }
    }
  }

  function overwriteArrays(arr) {
    for (let i = 0; i < arr.length; i++) {
      if (Array.isArray(arr[i])) {
        if (arr[i][0] === "x") {
          arr[i] = "X"; // Overwrite with 'X'
        } else {
          arr[i] = arr[i][0]; // Overwrite with the value inside the array
        }
      }
    }
  }

  function jeDuplikat(item, array) {
    const isDuplicate = array.some(
      (existingItem) => JSON.stringify(existingItem) === JSON.stringify(item)
    );

    if (!isDuplicate) {
      return false;
    } else {
      return true;
    }
  }

  const pridatVynimku = () => {
    if (stlacene !== initialCounters) {
      setError(null);

      setZakazane_moznosti((prevData) => {
        let kuKtorej = 0;
        prevData.forEach((e, i) => {
          if (e.poznamka === cisloVynimkyRef.current) {
            kuKtorej = i;
          }
        });

        const vybrataPoznamka = prevData[kuKtorej];

        let uplneVsetkyVynimky = prevData.flatMap((obj) => obj.vynimky);

        let vsetky_vynimky = [...vybrataPoznamka.vynimky];

        let viacvybratych = false;

        // dlzka objednavacieho kodu
        for (let i = 0; i < 7; i++) {
          // ked na niektorom selektnute viac ako 1
          if (stlacene[i].length > 1) {
            viacvybratych = true;
            for (let j = 0; j < stlacene[i].length; j++) {
              let momentalna_moznost = [...stlacene];

              momentalna_moznost[i] = momentalna_moznost[i][j];
              removeXArrays(momentalna_moznost);
              overwriteArrays(momentalna_moznost);

              if (!jeDuplikat(momentalna_moznost, uplneVsetkyVynimky)) {
                vsetky_vynimky = [...vsetky_vynimky, momentalna_moznost];
              }
            }
          }
        }
        // pokial je selectnuta iba jedna vynimka
        if (!viacvybratych) {
          let jednaVynimka = [...stlacene];
          removeXArrays(jednaVynimka);
          overwriteArrays(jednaVynimka);
          if (!jeDuplikat(jednaVynimka, uplneVsetkyVynimky)) {
            vsetky_vynimky = [...vsetky_vynimky, jednaVynimka];
          } else {
            setError("Výnimka " + jednaVynimka.join("") + " už existujé.");
          }
        }

        const updatedLastObject = {
          ...vybrataPoznamka,
          vynimky: [...vsetky_vynimky],
        };

        let newData = [];
        if (kuKtorej !== prevData.length - 1) {
          newData = [
            ...prevData.slice(0, kuKtorej),
            updatedLastObject,
            ...prevData.slice(kuKtorej + 1),
          ];
        } else {
          newData = [...prevData.slice(0, kuKtorej), updatedLastObject];
        }
        setMomentalnyPocetVynimiek(countVynimkyElements(newData));

        return newData;
      });
    }
    setStlacene(initialCounters);
  };

  const novaVynimka = () => {
    cisloVynimkyRef.current = nazovVynimky;
    setCisloVynimky(nazovVynimky);
    let poznamkyVsetky = [];
    zakazane_moznosti.forEach((e) => {
      poznamkyVsetky.push(e.poznamka);
    });

    if (!poznamkyVsetky.includes(nazovVynimky)) {
      setZakazane_moznosti([
        ...zakazane_moznosti,
        {
          poznamka: nazovVynimky,
          popis: popisVynimky,
          vynimky: [],
        },
      ]);
      setError(null);
      setNazovVynimky("");
      setPopisVynimky("");
    } else {
      setError("Poznámka " + nazovVynimky + " už je pridaná");
      setNazovVynimky("");
      setPopisVynimky("");
    }
  };

  const handleDocumentacia = (e) => {
    setOpenDocumentacia(e);
  };

  return (
    <>
      {vsetkyUdaje && !nacitava && (
        <>
          <div className={showModal ? "zoslabenie" : ""}>
            <div id="headerup">
              {" "}
              <img
                id="back_header"
                src={sipka}
                alt="sipka"
                onClick={() =>
                  navigate("/menu", {
                    state: {
                      zaheslovane,
                    },
                  })
                }
                style={{ cursor: "pointer" }}
              ></img>
              <img
                src={regada_picture}
                id="typografia_header"
                alt="typography"
                onClick={() =>
                  navigate("/menu", {
                    state: {
                      zaheslovane,
                    },
                  })
                }
                style={{ cursor: "pointer" }}
              ></img>
              <div style={{ width: "60px" }}></div>
            </div>

            <DocumentaciaDialog
              openDocumentacia={openDocumentacia}
              handleDocumentacia={handleDocumentacia}
              ktoraDocumentacia={"vynimky"}
            />

            <AlertDialogSlide
              open={open}
              sprava="Výnimky boli úspešne upravené"
            />

            <div className="obalena_tab1">
              <div className="vynimky-margin">
                <div className="nazov_obrazok">
                  <div>
                    <h1 id="nazov">{vsetkyUdaje.nazov}</h1>
                    <div id="vybavenia_obvidva">
                      <div>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <h2 className="vybavenia_nazvy">
                            Pridávanie Výnimiek
                          </h2>
                          <InfoIcon
                            htmlColor="#005e9d"
                            onClick={() => setOpenDocumentacia(true)}
                            style={{ cursor: "pointer", marginBottom: "16px" }}
                          />
                        </div>

                        <h2
                          className="vybavenia_nazvy"
                          style={{ margiTop: "-8px" }}
                        >
                          Celkový počet: {momentalnyPocetVynimiek}
                        </h2>
                        {zakazane_moznosti.length !== 0 ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                              marginTop: "30px",
                            }}
                          >
                            <h2 className="vybavenia_nazvy">
                              Vyberte poznámku pre pridávanie výnimiek
                            </h2>
                            <Box sx={{ minWidth: 80 }}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Číslo
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={cisloVynimky}
                                  label="cislo"
                                  onChange={(e) => {
                                    setCisloVynimky(e.target.value);
                                    cisloVynimkyRef.current = e.target.value;
                                    setTimeout(() => {
                                      const selectElement =
                                        document.getElementById(
                                          "demo-simple-select"
                                        );
                                      if (selectElement) {
                                        selectElement.blur();
                                      }
                                    }, 100);
                                  }}
                                >
                                  {zakazane_moznosti.map((e) => {
                                    return (
                                      <MenuItem value={e.poznamka}>
                                        {e.poznamka}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="board">
                  <div>
                    <div className="container">
                      {classyTabulky.map((e, i) => {
                        return (
                          <VynimkyTabulka
                            tabulka={
                              vsetkyUdaje["tabulka" + (i + 1).toString()]
                            }
                            cisloHlavnejTab={i + 1}
                            classPreTabulka={e}
                            stlacene={stlacene}
                            viacMoznosti={viacMoznosti}
                            updateStlacene={updateStlacene}
                            updateViacMoznosti={updateViacMoznosti}
                            poznamka={
                              vsetkyUdaje.poznamka ? vsetkyUdaje.poznamka : ""
                            }
                          />
                        );
                      })}

                      <VynimkyTabulkaSiedma
                        tabulka={vsetkyUdaje["tabulka7"]}
                        stlacene={stlacene}
                        updateStlacene={updateStlacene}
                      ></VynimkyTabulkaSiedma>
                    </div>
                  </div>

                  <div className="fixed-bottom-right">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        flexDirection: "column",
                        gap: "7px",
                      }}
                    >
                      <div style={{ display: "flex" }}></div>

                      <TextField
                        id="standard-basic"
                        label="Číslo poznámky:"
                        onChange={(e) => {
                          setNazovVynimky(e.target.value);
                          setError(null);
                        }}
                        value={nazovVynimky}
                        variant="standard"
                      />

                      <div>Popis poznámky:</div>
                      <MaxHeightTextarea
                        width="360px"
                        placeholder="..."
                        onChange={handleTextareaChange}
                        value={popisVynimky}
                      ></MaxHeightTextarea>

                      {/* <Textarea
                      aria-label="minimum height"
                      minRows={2}

                    /> */}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={
                            nazovVynimky.length > 0
                              ? () => novaVynimka()
                              : () => {}
                          }
                        >
                          Nová poznámka
                        </Button>

                        {zakazane_moznosti.length > 0 && (
                          <Button onClick={pridatVynimku} variant="outlined">
                            Pridať výnimku
                          </Button>
                        )}
                      </div>
                      {error && (
                        <Stack
                          sx={{ width: "400px", margin: "4px 0" }}
                          spacing={2}
                        >
                          <Alert
                            severity="error"
                            onClose={() => {
                              setError(null);
                            }}
                          >
                            {error}
                          </Alert>
                        </Stack>
                      )}
                      <h2 className="kod_dole">Zakázané možnosti:</h2>
                      <div className="oknodole_vynimky">
                        <div>
                          {zakazane_moznosti.map((objekty) => {
                            return (
                              <div className="vynimky-poznamky">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "5px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <h2>Poznámka {objekty.poznamka}</h2>
                                  <div
                                    className="close-icon"
                                    onClick={() => {
                                      let noveZakazane =
                                        zakazane_moznosti.filter(
                                          (e) => e.poznamka !== objekty.poznamka
                                        );
                                      setMomentalnyPocetVynimiek(
                                        countVynimkyElements(noveZakazane)
                                      );
                                      setZakazane_moznosti(noveZakazane);
                                      if (noveZakazane.length > 0) {
                                        cisloVynimkyRef.current =
                                          noveZakazane[0].poznamka;

                                        setCisloVynimky(
                                          noveZakazane[0].poznamka
                                        );
                                      }
                                    }}
                                  >
                                    ✖
                                  </div>
                                </div>
                                <div style={{ marginBottom: "10px" }}>
                                  <MaxHeightTextarea
                                    placeholder="Popis výnimky"
                                    value={objekty.popis}
                                    onChange={(e) => {
                                      const nextCounters =
                                        zakazane_moznosti.map((c) => {
                                          if (c.poznamka === objekty.poznamka) {
                                            // Increment the clicked counter
                                            c.popis = e.target.value;
                                            return c;
                                          } else {
                                            // The rest haven't changed
                                            return c;
                                          }
                                        });
                                      setZakazane_moznosti(nextCounters);
                                    }}
                                  ></MaxHeightTextarea>
                                </div>
                                <div
                                  style={{
                                    marginBottom: "5px",
                                  }}
                                >
                                  {objekty.vynimky.map((e, indexVynimky) => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        <div>{e}</div>

                                        <img
                                          src={deleteIcon}
                                          alt="del"
                                          className="trashcan"
                                          onClick={() => {
                                            const nextCounters =
                                              zakazane_moznosti.map((c) => {
                                                if (
                                                  c.poznamka ===
                                                  objekty.poznamka
                                                ) {
                                                  // Increment the clicked counter
                                                  c.vynimky = c.vynimky.filter(
                                                    (e, i) => i !== indexVynimky
                                                  );
                                                  return c;
                                                } else {
                                                  // The rest haven't changed
                                                  return c;
                                                }
                                              });

                                            setZakazane_moznosti(nextCounters);
                                            setMomentalnyPocetVynimiek(
                                              countVynimkyElements(nextCounters)
                                            );
                                          }}
                                        ></img>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div
                        className="tlacitko"
                        style={{ width: "58px" }}
                        onClick={() => {
                          setShowModal(true);
                          setError(null);
                          setVsetkyUdaje({
                            ...vsetkyUdaje,
                            zakazana_moznost: zakazane_moznosti,
                          });
                        }}
                      >
                        Upraviť
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showModal && (
            <OknoEdit
              setShowModal={setShowModal}
              vsetkyUdaje={vsetkyUdaje}
              setNacitava={setNacitava}
              handlePopUp={handlePopUp}
              nadpisForm="Chcete naozaj upraviť výnimky ?"
              popisForm="Pridávate výnimky pre servopohon:"
              pocetZmenych={(
                momentalnyPocetVynimiek - pocetPovodnych
              ).toString()}
            />
          )}
        </>
      )}

      {(!vsetkyUdaje || nacitava) && <Loading />}
      {/* {nacitava && <Loading />} */}
    </>
  );
};

export default Vynimky;
