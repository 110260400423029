import { closestCenter, DndContext } from "@dnd-kit/core";

import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { CSS } from "@dnd-kit/utilities";

const EditTabulka = ({
  tabulka,
  cisloHlavnejTab,
  mazanie,
  stlpce,
  updateVsetkyUdaje,
  vsetkyUdaje,
  classPreTabulka,
  presuvanie,
}) => {
  const handleNazovChange = (novaHodnota, indexTabulky, indexNazov) => {
    const update_hodnoty = tabulka;

    update_hodnoty[indexTabulky].nazvy[indexNazov] = novaHodnota;

    updateVsetkyUdaje({
      ...vsetkyUdaje,
      ["tabulka" + cisloHlavnejTab.toString()]: update_hodnoty,
    });
  };

  const handleGroupNazov = (
    novyNazov,
    indexTabulky,
    indexNazov,
    indexSkupiny,
    indexVSkupine
  ) => {
    // niektore tabuloky sa kopiruju

    const update_hodnoty = tabulka;

    update_hodnoty[indexTabulky].nazvy[indexNazov][indexSkupiny][
      indexVSkupine
    ] = novyNazov;

    updateVsetkyUdaje({
      ...vsetkyUdaje,
      ["tabulka" + cisloHlavnejTab.toString()]: update_hodnoty,
    });
  };

  const handlehodnotaChange = (
    novaHodnota,
    indexTabulky,
    indexRiadka,
    indexHodnoty
  ) => {
    const update_hodnoty = tabulka;

    update_hodnoty[indexTabulky].hodnoty[indexRiadka][indexHodnoty] =
      novaHodnota;

    updateVsetkyUdaje({
      ...vsetkyUdaje,
      ["tabulka" + cisloHlavnejTab.toString()]: update_hodnoty,
    });
  };

  const odstranitHodnotuRiadok = (
    indexRiadka,
    hodnotaRiadok,
    indexTabulkyNested
  ) => {
    let update_hodnoty = tabulka[indexTabulkyNested].hodnoty;

    update_hodnoty[indexRiadka] = update_hodnoty[indexRiadka].filter(
      (e) => e !== hodnotaRiadok
    );

    updateVsetkyUdaje({
      ...vsetkyUdaje,
      ["tabulka" + cisloHlavnejTab.toString()]: [
        ...vsetkyUdaje["tabulka" + cisloHlavnejTab.toString()],
      ],
    });
  };

  const odstranitRiadok = (riadok, indexTabulkyNested) => {
    let update_hodnoty = tabulka[indexTabulkyNested];
    update_hodnoty.hodnoty = update_hodnoty.hodnoty.filter((e) => e !== riadok);

    updateVsetkyUdaje({ ...vsetkyUdaje });
  };

  const pridatRiadok = (cisloTabulky, indexTabulky) => {
    const update_hodnoty = vsetkyUdaje["tabulka" + cisloTabulky][indexTabulky];
    let novyRiadok = new Array(3).fill("");
    if (update_hodnoty.hodnoty[0]) {
      novyRiadok = new Array(update_hodnoty.hodnoty[0].length).fill("");
    }

    update_hodnoty.hodnoty.push(novyRiadok);

    updateVsetkyUdaje({ ...vsetkyUdaje });
  };

  // DRAG IMPLEMENTETION

  const onDragEnd = (event) => {
    const { active, over } = event;
    const dekodovaneId = active.id.split("-");

    if (active.id === over.id) {
      return;
    }
    // ID CO SETNEM RIADKU MUSI BYT TAKE ZE HO VIEM POTOM SPATNE DESIFROVAT
    // HlavnaTabulka, Podtabulka, IndexRiadka

    const HlavnaTabulka = dekodovaneId[0];
    const Podtabulka = dekodovaneId[1];
    const IndexRiadka = dekodovaneId[2];

    updateVsetkyUdaje((vsetkyUdaje) => {
      const oldIndex = IndexRiadka;
      const newIndex = over.id.split("-")[2];

      const update_hodnoty = tabulka;

      update_hodnoty[Podtabulka].hodnoty = arrayMove(
        vsetkyUdaje["tabulka" + HlavnaTabulka][Podtabulka].hodnoty,
        oldIndex,
        newIndex
      );

      return { ...vsetkyUdaje, ["tabulka" + HlavnaTabulka]: update_hodnoty };
    });
  };

  const SortableHodnota = ({
    indexRiadka,
    indexTabulky,
    hodnota,
    pocetRiadkov,
  }) => {
    const idPreCurrentTabulku = Array.from(Array(pocetRiadkov).keys()).map(
      (e) =>
        cisloHlavnejTab.toString() + "-" + indexTabulky.toString() + "-" + e
    );
    // musia byt unikatne koncovky v jednej tabulke, v dalsich sa opakuju
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({
        id: idPreCurrentTabulku[indexRiadka],
      });

    const style = {
      transition,
      transform: CSS.Transform.toString(transform),
    };

    return (
      <div className="edit-row-cely">
        <div
          key={hodnota.toString() + indexRiadka}
          className="table-row-edit"
          ref={setNodeRef}
          style={style}
          {...attributes}
          {...listeners}
        >
          {hodnota.map((hodnotaRiadok, indexHodnoty) => {
            return (
              <div
                className="table-data-edit-tlacitko"
                key={hodnotaRiadok + indexHodnoty}
              >
                <div style={{ textAlign: "center" }}>{hodnotaRiadok}</div>
              </div>
            );
          })}
        </div>
        <button
          className="tlacitko-vymazat"
          onClick={() => {
            odstranitRiadok(hodnota, indexTabulky);
          }}
        >
          x
        </button>
      </div>
    );
  };

  // ZMENIT PRVA TABULKA na 1 TABULKA
  return (
    <>
      {tabulka.map((hodnotaArray, indexTabulky) => {
        return (
          <div
            id={classPreTabulka + indexTabulky}
            className={classPreTabulka + "_tabulka"}
          >
            {/* NAZVY */}
            <div className="cele_nazvy">
              {hodnotaArray.nazvy.map((group, indexNazov) => {
                if (Array.isArray(group)) {
                  let pocet_stlpcov = group[group.length - 1].length;
                  return (
                    <div
                      key={indexNazov}
                      className={"group flex" + pocet_stlpcov}
                    >
                      {group.map((row, indexSkupiny) => (
                        <div key={indexSkupiny} className="row">
                          {row.map((name, indexVSkupine) => (
                            <input
                              className="element_nazov_edit"
                              key={indexVSkupine}
                              value={name}
                              onChange={(e) => {
                                handleGroupNazov(
                                  e.target.value,
                                  indexTabulky,
                                  indexNazov,
                                  indexSkupiny,
                                  indexVSkupine
                                );
                              }}
                            ></input>
                          ))}
                        </div>
                      ))}
                    </div>
                  );
                } else {
                  return (
                    <div className="row">
                      <input
                        className="element_nazov_edit"
                        placeholder="..."
                        value={group}
                        onChange={(e) =>
                          handleNazovChange(
                            e.target.value,
                            indexTabulky,
                            indexNazov
                          )
                        }
                      />
                    </div>
                  );
                }
              })}

              <div className="row">
                <div className="element_nazov">↓</div>
              </div>
            </div>

            {/* HODNOTY */}

            <div className="table-content">
              {presuvanie ? (
                <DndContext
                  collisionDetection={closestCenter}
                  onDragEnd={onDragEnd}
                >
                  <SortableContext
                    // items={hodnotaArray.hodnoty}
                    items={Array.from(
                      Array(hodnotaArray.hodnoty.length).keys()
                    ).map(
                      (e) =>
                        cisloHlavnejTab.toString() +
                        "-" +
                        indexTabulky.toString() +
                        "-" +
                        e
                    )}
                    strategy={verticalListSortingStrategy}
                  >
                    {" "}
                    {hodnotaArray.hodnoty.map((hodnota, indexRiadka) => {
                      // toto dat do compo
                      return (
                        <SortableHodnota
                          key={
                            cisloHlavnejTab.toString() +
                            "-" +
                            indexTabulky.toString() +
                            "-" +
                            indexRiadka.toString()
                          }
                          hodnota={hodnota}
                          indexRiadka={indexRiadka}
                          indexTabulky={indexTabulky}
                          pocetRiadkov={hodnotaArray.hodnoty.length}
                        />
                      );
                    })}
                  </SortableContext>
                </DndContext>
              ) : (
                hodnotaArray.hodnoty.map((hodnota, indexRiadka) => {
                  let indexy = 0;

                  return (
                    <div className="edit-row-cely">
                      <div
                        key={indexy.toString() + indexRiadka}
                        className="table-row-edit"
                      >
                        {hodnota.map((hodnotaRiadok, indexHodnoty) => {
                          return (
                            <div className="table-data-edit-tlacitko">
                              <input
                                className="table-data-edit"
                                value={hodnotaRiadok}
                                placeholder="..."
                                onChange={(e) =>
                                  handlehodnotaChange(
                                    e.target.value,
                                    indexTabulky,
                                    indexRiadka,
                                    indexHodnoty
                                  )
                                }
                              />
                              <button
                                hidden={mazanie ? false : true}
                                className="tlacitko-vymazat-male"
                                onClick={() =>
                                  odstranitHodnotuRiadok(
                                    indexRiadka,
                                    hodnotaRiadok,
                                    indexTabulky
                                  )
                                }
                              >
                                x
                              </button>
                            </div>
                          );
                        })}
                      </div>

                      <button
                        className="tlacitko-vymazat"
                        onClick={() => {
                          odstranitRiadok(hodnota, indexTabulky);
                        }}
                      >
                        x
                      </button>
                    </div>
                  );
                })
              )}
            </div>

            <div
              hidden={
                !stlpce
                  ? true
                  : tabulka.length - 1 === indexTabulky
                  ? false
                  : true
              }
            >
              <div>Stĺpce zmazať</div>

              <div
                className="table-row-edit-column"
                style={{
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                {tabulka[0].hodnoty[0]
                  ? [...Array(tabulka[0].hodnoty[0].length).keys()].map(
                      (e, indexStplca) => {
                        return (
                          <button
                            className="stlpce tlacitko-vymazat"
                            onClick={() => {
                              let taTabulka = [...tabulka];

                              taTabulka.forEach((element, indexKtoraTab) => {
                                element.hodnoty.forEach(
                                  (element2, indexKtoraHodnota) => {
                                    taTabulka[indexKtoraTab].hodnoty[
                                      indexKtoraHodnota
                                    ] = element2.filter(
                                      (e, i) => i !== indexStplca
                                    );
                                  }
                                );
                              });
                              updateVsetkyUdaje({
                                ...vsetkyUdaje,
                                ["tabulka" + cisloHlavnejTab.toString()]:
                                  taTabulka,
                              });
                            }}
                          >
                            {indexStplca + 1}
                          </button>
                        );
                      }
                    )
                  : ""}
              </div>

              <div>Stĺpce pridať</div>

              <div
                className="table-row-edit"
                style={{
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                {tabulka[0].hodnoty[0]
                  ? [...Array(tabulka[0].hodnoty[0].length).keys()].map(
                      (e, indexStplca) => {
                        return (
                          <button
                            className="stlpce tlacitko-pridat-stlpec"
                            onClick={() => {
                              let taTabulka = tabulka;

                              taTabulka.forEach((element, indexKtoraTab) => {
                                element.hodnoty.forEach(
                                  (element2, indexKtoraHodnota) => {
                                    taTabulka[indexKtoraTab].hodnoty[
                                      indexKtoraHodnota
                                    ].splice(indexStplca + 1, 0, "");
                                  }
                                );
                              });
                              updateVsetkyUdaje({
                                ...vsetkyUdaje,
                                ["tabulka" + cisloHlavnejTab.toString()]:
                                  taTabulka,
                              });
                            }}
                          >
                            {indexStplca + 1}
                          </button>
                        );
                      }
                    )
                  : ""}
              </div>
            </div>

            <button
              className="tlacitko"
              style={{ marginBottom: "10px" }}
              onClick={() => pridatRiadok(cisloHlavnejTab, indexTabulky)}
            >
              Pridať riadok +
            </button>
          </div>
        );
      })}
      <button
        className="tlacitko"
        style={{ marginBottom: "10px" }}
        onClick={() =>
          updateVsetkyUdaje({
            ...vsetkyUdaje,
            ["tabulka" + cisloHlavnejTab.toString()]: [
              ...vsetkyUdaje["tabulka" + cisloHlavnejTab.toString()],
              { nazvy: [], hodnoty: [] },
            ],
          })
        }
      >
        Pridať podtabuľku +
      </button>
    </>
  );
};

export default EditTabulka;
