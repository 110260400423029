import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function DocumentaciaDialog({
  openDocumentacia,
  handleDocumentacia,
  ktoraDocumentacia,
}) {
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={() => handleDocumentacia(false)}
        aria-labelledby="customized-dialog-title"
        open={openDocumentacia}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Dokumentácia
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => handleDocumentacia(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        {ktoraDocumentacia === "vseobPoznamky" ? (
          <DialogContent dividers>
            <Typography gutterBottom>
              K dispozícii sú nasledovné možnosti úpravy: mazanie, pridávanie a
              upravovanie.
            </Typography>

            <Typography gutterBottom>
              V prípade že poznámka napr. č. 11 sa nachádza aj vo všeobecných
              ale a aj v nejakom serve ako individuálna poznámka tak v tom
              prípade je všeobecná poznámka ignorovaná.
            </Typography>
          </DialogContent>
        ) : ktoraDocumentacia === "edit" ? (
          <DialogContent dividers>
            <Typography gutterBottom>
              K dispozícii sú nasledovné možnosti úpravy: zmena typového čísla,
              názvu serva, štandardného a rozšíreného vybavenie. Nasledovne je
              možne upravovať názvy aj hodnoty v tabuľkách. Pridávať nove riadky
              ku každej z tabuliek a vytvárať pod tabuľky ako napr. tabuľka 3 v
              serve SO 2.
            </Typography>

            <Typography gutterBottom>
              <b>Zapisovanie poznámok:</b> vždy na koniec, oddelene medzerou od
              posledného slova. Napr. Korózna kategória 10)
            </Typography>

            <Typography gutterBottom>
              <b>Špeciálne znaky:</b> <b>*</b>(znak krát) sa používa pokiaľ
              chceme priradiť jednu hodnotu viacerým bunkám, napr. pri názvoch v
              tabuľke 5 (servo SPR 0.1PA) <i>Ovládanie - Riadiace vstupy</i>,
              zaberá dokopy 5 buniek, znak <b>#</b> sa používa pokiaľ danú
              hodnotu v nazve necheme zobraziť napr. S0 2 tretia tabuľka
            </Typography>

            <Typography gutterBottom>
              <b>Módy:</b> <i>Povoliť mazanie hodnôt</i> - umožni zmazať
              hociktorú hodnotu, <i>Povoliť mazanie a pridávanie stĺpcov</i> -
              vymaže alebo pridá celý stĺpec v tabuľke,{" "}
              <i>Povoliť presúvanie</i> - zakáže sa prepisovanie a všetky
              hodnoty sa v rámci tabuľky môžu posúvať, to iste platí pre názvy
            </Typography>

            <Typography gutterBottom>
              <b>Veci na ktoré si dávať pozor:</b> nepoužívať formát (ISO 5211)
              ale buď bez zátvoriek alebo ISO (5211), znak "*" nepoužívať na
              začiatku hodnôt/názvoch
            </Typography>
          </DialogContent>
        ) : ktoraDocumentacia === "vynimky" ? (
          <DialogContent dividers>
            <Typography gutterBottom>
              <b>Ovládanie:</b> V pravom dolnom rohu sa nachádza textové pole na
              číslo poznámky a popisu k poznámke, k nim sa následne budú
              pridávať výnimky. Po pridaní novej poznámky sa zobrazí v
              zakázaných možnostiach. Ak mame pridanú aspoň jednu poznámku tak
              môžeme pridávať výnimky pomocou tlačidla „pridať výnimky“ alebo
              pomocou Enter-u, prepínať poznámku do ktorej pridávame výnimky si
              môžeme zvoliť na začiatku "Vyberte poznámku pre pridávanie
              výnimiek".
            </Typography>
          </DialogContent>
        ) : ktoraDocumentacia === "obrazky" ? (
          <DialogContent dividers>
            <Typography gutterBottom>
              <b>Vyhľadávanie:</b> bez koncoviek.<br></br>
              <b>Nahrávanie:</b> možne iba po jednom.
            </Typography>
          </DialogContent>
        ) : (
          ""
        )}

        <DialogActions>
          <Button autoFocus onClick={() => handleDocumentacia(false)}>
            Zatvoriť
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
