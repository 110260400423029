import Nazvy from "../Nazvy";
import Hodnota from "../Hodnota";

const VynimkyTabulka = ({
  tabulka,
  cisloHlavnejTab,
  classPreTabulka,
  stlacene,
  viacMoznosti,
  updateStlacene,
  updateViacMoznosti,
  poznamka,
}) => {
  const handleClick = (elem, index) => {
    // odtuknutnutie
    if (stlacene[index].includes(elem)) {
      const nextCounters = stlacene.map((c, i) => {
        if (i === index) {
          // ked chces odtuknut to co uz je stalcene
          if (stlacene[i].length === 1) {
            return ["x"];
          } else {
            return stlacene[i].filter((pismeno) => pismeno !== elem);
          }
        } else {
          // The rest haven't changed
          return c;
        }
      });

      updateStlacene(nextCounters);
      return 0;
    }

    const nextCounters = stlacene.map((c, i) => {
      if (i === index) {
        // stalecene a zaznamenam
        if (stlacene[i][0] === "x") {
          return [elem];
        } else {
          if (
            (!viacMoznosti ||
              viacMoznosti === i.toString() ||
              stlacene[parseInt(viacMoznosti)].length === 1) &&
            i !== 0
          ) {
            updateViacMoznosti(i.toString());

            return [...stlacene[i], elem];
          } else {
            return [elem];
          }
        }
      } else {
        // The rest haven't changed
        return c;
      }
    });

    updateStlacene(nextCounters);
  };

  return tabulka.map((hodnotaArray, indexTabulky) => {
    return (
      <div>
        {" "}
        <div
          id={classPreTabulka + indexTabulky}
          className={classPreTabulka + "_tabulka"}
        >
          <Nazvy tabulkaIteracia={hodnotaArray} poznamka={poznamka} />

          <div className="table-content">
            {" "}
            {hodnotaArray.hodnoty.map((hodnota, indexRiadka) => {
              let indexy = 0;
              if (cisloHlavnejTab !== 1) {
                indexy = cisloHlavnejTab;
              }

              let momentalny_kod = hodnota[hodnota.length - 1];
              return (
                <div
                  key={indexy.toString() + indexRiadka}
                  onClick={() => {
                    handleClick(momentalny_kod, indexy);
                  }}
                  className={
                    stlacene[indexy].includes(momentalny_kod)
                      ? "stisknute table-row"
                      : "nestisknute table-row"
                  }
                >
                  {hodnota.map((hodnotaRiadok, indexHodnoty) => {
                    return (
                      <Hodnota
                        hodnotaRiadok={hodnotaRiadok}
                        poznamka={poznamka}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  });
};

export default VynimkyTabulka;
